import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import { SecureRoute, LoginCallback } from '@okta/okta-react';
import Travel from './scenes/Travel';
import CreatePromotion from './scenes/Travel/scenes/Promotions/scenes/CreatePromotion';
import EditPromotion from './scenes/Travel/scenes/Promotions/scenes/EditPromotion';
import CreateCommissionSplit from './scenes/Travel/scenes/CommissionSplits/scenes/CreateCommissionSplit';
import EditCommissionSplit from './scenes/Travel/scenes/CommissionSplits/scenes/EditCommissionSplit';
import NotFound from '@/scenes/NotFound';
import Login from '@/scenes/Login';

const Routes: FunctionComponent = (): JSX.Element => {
  return (
    <Switch>
      {/* Travel */}
      <SecureRoute exact path="/" component={Travel} />
      {/* Travel - Promotions */}
      <SecureRoute exact path="/promotions/new" component={CreatePromotion} />
      <SecureRoute
        exact
        path="/promotions/:id/edit"
        component={EditPromotion}
      />
      {/* Travel - Commission Splits */}
      <SecureRoute
        exact
        path="/commission-splits/new"
        component={CreateCommissionSplit}
      />
      <SecureRoute
        exact
        path="/commission-splits/:id/edit"
        component={EditCommissionSplit}
      />
      {/* Login */}
      <Route path="/login" component={Login} />
      <Route path="/implicit/callback" component={LoginCallback} />
      {/* Page not found */}
      <SecureRoute component={NotFound} />
    </Switch>
  );
};

export default Routes;
