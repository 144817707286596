import { isEmpty } from 'ramda';
import {
  formatStartOfDayInZoneUTC,
  formatEndOfDayInZoneUTC,
} from '@/helpers/date';
import {
  CommissionSplitFormData,
  CommissionSplitRequestParams,
  CommissionSplitQualifier,
} from './CommissionSplitForm.types';

const transformUserSegmentToQualifier = (
  userSegment: string,
): CommissionSplitQualifier => {
  return userSegment === 'firstTimeBookers' ? 'FIRST_BOOKING_ONLY' : null;
};

export const transformCommissionSplitFormDataToParams = (
  values: CommissionSplitFormData,
): CommissionSplitRequestParams => {
  const params: CommissionSplitRequestParams = {
    offerId: values.offerId,
    from: formatStartOfDayInZoneUTC(values.startDate),
    end: formatEndOfDayInZoneUTC(values.endDate),
    cashbackPercentage: parseInt(values.cashbackPercentage, 10),
    qualifier: transformUserSegmentToQualifier(values.userSegment),
    minimumCommissionPercentage: parseInt(
      values.minimumCommissionPercentage,
      10,
    ),
    payoutDays: parseInt(values.payoutDays, 10),
  };

  if (!isEmpty(values.version)) {
    params.version = values.version;
  }

  return params;
};
