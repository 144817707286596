import React, { FunctionComponent } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Image } from 'react-bootstrap';
import Logo from '@/assets/images/logos/dosh.svg';
import Loader from '@/components/Loader';
import Button from '@/components/Button';
import Box from '@/components/Box';
import { SimpleLayout } from '@/components/Layouts';
import './Login.css';

const Login: FunctionComponent = (): JSX.Element => {
  const { authState, oktaAuth } = useOktaAuth();

  const login = async () => {
    oktaAuth.signInWithRedirect({ originalUri: '/' });
  };

  if (!authState) {
    return <Loader />;
  }

  return (
    <SimpleLayout>
      <div className="trv-login">
        <Box>
          <Image
            src={Logo}
            width={40}
            height={40}
            className="trv-login-brand"
          />
          <h1 className="trv-login-heading">Consumer Content Management</h1>
          <Button onClick={login} block>
            Log in with Okta
          </Button>
        </Box>
      </div>
    </SimpleLayout>
  );
};

export default Login;
