import React, { FunctionComponent, CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';
import Badge from '@/components/Badge/Badge';
import Table, { TableColumn, TableRow } from '@/components/Table/Table';
import TableLoader from '@/components/TableLoader';
import { formatDateInZone } from '@/helpers/date';
import { CommissionSplitTableProps } from './CommissionSplitTable.types';
import {
  getBadgeVariant,
  getOfferStatus,
} from '@/scenes/Travel/Travel.helpers';
import { OfferStatusType } from '@/scenes/Travel/Travel.types';

const formatCommissionSplit = (cashbackPercentage: number): JSX.Element => {
  const style: CSSProperties = { fontWeight: 500 };
  const consumerText: JSX.Element = <>{cashbackPercentage}% Consumer</>; // eslint-disable-line
  const doshText: JSX.Element = <>{100.0 - cashbackPercentage}% Dosh</>; // eslint-disable-line

  return (
    <>
      <span style={style}>{consumerText}</span>
      <br />
      <span style={style}>{doshText}</span>
    </>
  );
};

const formatPayoutAfter = (days: string): string => {
  return `${days} days`;
};

const formatStatus = (status: OfferStatusType): JSX.Element => {
  return <Badge variant={getBadgeVariant(status)}>{status}</Badge>;
};

const CommissionSplitTable: FunctionComponent<CommissionSplitTableProps> = ({
  commissionSplits,
  loading,
}): JSX.Element => {
  if (loading) {
    return <TableLoader />;
  }

  const history = useHistory();
  const columns: TableColumn[] = [
    {
      title: 'Commission Split',
      field: 'commissionSplit',
      format(tableCellValue) {
        return formatCommissionSplit(tableCellValue);
      },
    },
    {
      title: 'Payout After',
      field: 'payoutAfter',
      format(tableCellValue) {
        return formatPayoutAfter(tableCellValue);
      },
    },
    {
      title: 'Start Date',
      field: 'startDate',
      sortType: 'datetime',
      format(tableCellValue) {
        return formatDateInZone(tableCellValue, 'll');
      },
    },
    {
      title: 'End Date',
      field: 'endDate',
      sortType: 'datetime',
      format(tableCellValue) {
        return formatDateInZone(tableCellValue, 'll');
      },
    },
    {
      title: 'Status',
      field: 'status',
      format(tableCellValue) {
        return formatStatus(tableCellValue);
      },
    },
  ];

  const rows: TableRow[] = commissionSplits.map((commissionSplit) => {
    return {
      commissionSplit: commissionSplit.cashbackPercentage,
      payoutAfter: commissionSplit.payoutDays,
      startDate: commissionSplit.from,
      endDate: commissionSplit.end,
      status: getOfferStatus(commissionSplit.from, commissionSplit.end),
      meta: {
        offerId: commissionSplit.offerId,
      },
    };
  });

  const handleRowClick = (tableRow: TableRow): void => {
    history.push(`/commission-splits/${tableRow.meta.offerId}/edit`);
  };

  return <Table columns={columns} rows={rows} onClickRow={handleRowClick} />;
};

export default CommissionSplitTable;
