import { formatEndOfDayInZoneUTC } from '@/helpers/date';
import {
  EditLivePromotionFormData,
  EditLivePromotionRequestParams,
} from './EditLivePromotionForm.types';

export const transformEditLivePromotionFormDataToParams = (
  values: EditLivePromotionFormData,
): EditLivePromotionRequestParams => {
  return {
    offerId: values.offerId,
    end: formatEndOfDayInZoneUTC(values.endDate),
    name: values.promoName,
    version: values.version,
  };
};
