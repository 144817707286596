import React, { FunctionComponent, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { isEmpty } from 'ramda';
import { formatDateInZone } from '@/helpers/date';
import Container from '@/components/Container';
import Box from '@/components/Box';
import InAppPromoDisplayModal from '@/scenes/Travel/scenes/Promotions/components/InAppPromoDisplayModal';
import IconForOpeningModal from '@/scenes/Travel/scenes/Promotions/components/IconForOpeningModal';
import FormPreviewField from '@/components/FormPreviewField';
import FormPromotionRules from '../FormPromotionRules';
import { PromotionFormData } from '@/scenes/Travel/scenes/Promotions/components/PromotionForm/PromotionForm.types';

const formatCheckInOutDate = (date: string): string => {
  return !isEmpty(date) ? formatDateInZone(date) : '';
};

interface EditCompletedPromotionFormProps {
  initialValues: PromotionFormData;
}

const EditCompletedPromotionForm: FunctionComponent<EditCompletedPromotionFormProps> =
  ({ initialValues }): JSX.Element => {
    const [showPromoModal, setShowPromoModal] = useState<boolean>(false);

    return (
      <Box>
        <div style={{ maxWidth: '560px' }}>
          <Container noPadding>
            <Form.Row>
              <Col md>
                <FormPreviewField
                  label="Start Date"
                  value={formatDateInZone(initialValues.startDate)}
                />
              </Col>
              <Col md>
                <FormPreviewField
                  label="End Date"
                  value={formatDateInZone(initialValues.endDate)}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md>
                <FormPreviewField
                  label="Check In After Date"
                  value={formatCheckInOutDate(initialValues.checkInAfter)}
                />
              </Col>
              <Col md>
                <FormPreviewField
                  label="Check In Before Date"
                  value={formatCheckInOutDate(initialValues.checkInBefore)}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md>
                <FormPreviewField
                  label="Check Out After Date"
                  value={formatCheckInOutDate(initialValues.checkOutAfter)}
                />
              </Col>
              <Col md>
                <FormPreviewField
                  label="Check Out Before Date"
                  value={formatCheckInOutDate(initialValues.checkOutBefore)}
                />
              </Col>
            </Form.Row>
            <FormPreviewField
              label="Promotion Name"
              value={initialValues.promoName}
            />
            <FormPromotionRules initialValues={initialValues} />
            <FormPreviewField
              label="In-App Title"
              value={initialValues.inAppTitle}
              icon={
                <IconForOpeningModal onClick={() => setShowPromoModal(true)} />
              }
            />
            <FormPreviewField
              label="In-App Headline"
              value={initialValues.inAppHeadline}
              icon={
                <IconForOpeningModal onClick={() => setShowPromoModal(true)} />
              }
            />
            <FormPreviewField
              label="In-App Confirmation"
              value={initialValues.inAppConfirmation}
              icon={
                <IconForOpeningModal onClick={() => setShowPromoModal(true)} />
              }
            />
            <FormPreviewField
              label="In-App Promotion Description"
              value={initialValues.inAppPromotionDescription}
              icon={
                <IconForOpeningModal onClick={() => setShowPromoModal(true)} />
              }
            />
          </Container>
        </div>
        <InAppPromoDisplayModal
          show={showPromoModal}
          onHide={() => setShowPromoModal(false)}
        />
      </Box>
    );
  };

export default EditCompletedPromotionForm;
