export const PROMOTION_FORM_SUBMIT_BTN_ID = 'promotion-form-submit-btn';

export type PromotionQualifier =
  | 'EVERY_BOOKING'
  | 'FIRST_BOOKING_ONLY'
  | 'PROMO_CODE_ENTRY';

export interface PromotionFormData {
  offerId: string;
  startDate: string;
  endDate: string;
  version: string;
  checkInBefore: string;
  checkInAfter: string;
  checkOutBefore: string;
  checkOutAfter: string;
  promoName: string;
  promoCode: string;
  cashbackBonusRule: string;
  cashbackAmount: string;
  cashbackMultiplier: string;
  minimumCashbackAmount: string;
  minimumBookingTotalAmount: string;
  offerType: string;
  userSegment: string;
  testPromo: string;
  maxUsage: string;
  inAppTitle: string;
  inAppHeadline: string;
  inAppConfirmation: string;
  inAppPromotionDescription: string;
}

export interface PromotionFormProps {
  validateOnMount: boolean;
  initialValues: PromotionFormData;
  mutation: any;
  errorMessage: string;
  onLoading(loading: boolean): void;
  onSave?(): void;
  redirectAfterSave?: {
    pathname: string;
    state: {
      flashMessage: string;
    };
  };
}

export interface PromotionRequestParams {
  offerId: string;
  from: string;
  end: string;
  name: string;
  title: string;
  headline: string;
  paragraph: string;
  terms: string;
  promotionMessage: string;
  cashbackCurrency: 'USD';
  qualifiers: PromotionQualifier[];
  promoCode: string;
  maxUseCount: number;
  testOffer: boolean;
  cashbackAmount: number | null;
  cashbackPercentage: number | null;
  minimumCashbackAmount: number | null;
  minimumBookingTotalAmount: number | null;
  version?: string;
  checkInBefore?: string;
  checkInAfter?: string;
  checkOutBefore?: string;
  checkOutAfter?: string;
}
