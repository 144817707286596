import * as Yup from 'yup';
import moment from 'moment';
import { getGraphQLError } from './getGraphQLError';

export const VALIDATION_ERROR_MSG =
  'The form contains validation errors that need to be fixed';

export type ValidationShape = Record<
  string,
  Yup.NumberSchema | Yup.StringSchema | Yup.DateSchema
>;

export type ValidationSchema = Yup.ObjectSchema<any>;

export const getNumberValidator = (
  label: string,
  required: boolean = true,
): Yup.NumberSchema => {
  let validationSchema: Yup.NumberSchema = Yup.number()
    .typeError('${label} must be an integer') // eslint-disable-line
    .integer()
    .min(0)
    .label(label);

  if (required) {
    validationSchema = validationSchema.required();
  }

  return validationSchema;
};

export const getStringValidator = (label: string): Yup.StringSchema => {
  return Yup.string().required().label(label);
};

/**
 * Validates that some field contains a valid string; The validation
 * is run only when the `requiredFieldName` has value as well.
 *
 * This method is useful in cases when we have two inputs which can be empty,
 * but when one of them contains value, the other one needs to be provided
 * value as well.
 *
 * @param {string} label A label for the field we are validating e.g. "Start Date"
 * @param {string} requiredFieldName HTML5 input name for the field we are validating against e.g. "endDate"
 * @returns {Yup.StringSchema} Yup string validation schema
 */
export const getConditionalStringValidator = (
  label: string,
  requiredFieldName: string,
): Yup.StringSchema => {
  return Yup.string().when(requiredFieldName, {
    is: (value: string) => value && value.length > 0,
    then: getStringValidator(label),
  });
};

/**
 * Validates that some field contains an end date which comes after start date;
 * The validation is run only when the `startDateFieldName` has value as well.
 *
 * @param {string} label A label for the field we are validating e.g. "End Date"
 * @param {string} startDateFieldLabel A label for the field we are validating against e.g. "Start Date"
 * @param {string} startDateFieldName HTML5 input name for the field we are validating against e.g. "startDate"
 * @returns {Yup.StringSchema} Yup string validation schema
 */
export const getEndDateValidator = (
  label: string,
  startDateFieldLabel: string,
  startDateFieldName: string,
): Yup.StringSchema => {
  return Yup.string().when(startDateFieldName, {
    is: (value: string) => value && value.length > 0,
    then: Yup.string()
      .required()
      .test(
        `comesAfter-${startDateFieldName}`,
        `Should come after ${startDateFieldLabel}`,
        function (value) {
          return moment(value).isAfter(moment(this.parent[startDateFieldName]));
        },
      )
      .label(label),
  });
};

export const getValidationError = (error: any): string => {
  return getGraphQLError(
    {
      displayMessage: 'Something went wrong',
      message: '',
    },
    false,
    error,
  )[0].displayMessage;
};
