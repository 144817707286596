import React, { FunctionComponent, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Flash from '@/components/Flash';
import { updateAuthState } from '@/config';

const MainLayout: FunctionComponent = ({ children }): JSX.Element => {
  const { authState } = useOktaAuth();

  useEffect(() => {
    updateAuthState(authState);
  });

  return (
    <>
      {authState?.isAuthenticated && <Navbar />}
      <main>
        {children}
        <Flash />
      </main>
      <Footer />
    </>
  );
};

export default MainLayout;
