import React from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './config';
import 'react-toastify/dist/ReactToastify.min.css';
import './styles/global.css';
import SecureApp from './SecureApp';

toast.configure({
  toastClassName: 'trv-toast-container',
  draggable: false,
});

const App: React.FunctionComponent = (): JSX.Element => {
  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <SecureApp />
      </Router>
    </ApolloProvider>
  );
};

export default process.env.ENABLE_HOT_RELOADING ? hot(App) : App;
