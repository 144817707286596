import React, { FunctionComponent, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
  getGraphQLError,
  defaultErrorMessage,
  ModalError,
} from '@/helpers/getGraphQLError';
import { toastMapping } from '@/components/utils/ErrorBoundary/ErrorBoundary';
import DateFormatNotification from '@/scenes/Travel/components//DateFormatNotification';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import CommissionSplitTable from './components/CommissionSplitTable';
import { GET_COMMISSION_OFFERS } from './CommissionSplits.queries';
import './CommissionSplits.css';

const CommissionSplits: FunctionComponent = (): JSX.Element => {
  const { loading, error, data } = useQuery(GET_COMMISSION_OFFERS);

  useEffect(() => {
    if (error) {
      const formattedError: ModalError[] = getGraphQLError(
        defaultErrorMessage,
        false,
        error,
      );
      toastMapping({}, 'error')(formattedError);
    }
  }, [error]);

  const displayLoaders: boolean = loading || !!error;
  const commissionSplits: any[] = displayLoaders
    ? []
    : data.travelCommissionOffers.offers;

  return (
    <>
      <div className="trv-commission-splits-header">
        <h1>Travel Commission Split Dashboard</h1>
        <Button
          href="/commission-splits/new"
          icon={<Icon type="add" color="#fff" />}
        >
          New Split
        </Button>
      </div>
      <CommissionSplitTable
        commissionSplits={commissionSplits}
        loading={loading}
      />
      <DateFormatNotification />
    </>
  );
};

export default CommissionSplits;
