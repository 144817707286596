import React, { FunctionComponent } from 'react';

interface FormPreviewFieldProps {
  label: string;
  value: string | number;
  icon?: JSX.Element;
}

const FormPreviewField: FunctionComponent<FormPreviewFieldProps> = ({
  label,
  value,
  icon,
}): JSX.Element => {
  /* eslint-disable */
  return (
    <>
      <h6>
        {label} {icon}
      </h6>
      <p className="mb-4">{value || '<value is not set>'}</p>
    </>
  );
  /* eslint-enable */
};

export default FormPreviewField;
