import React, { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { OfferStatusType } from '@/scenes/Travel/Travel.types';
import { getOfferStatus } from '@/scenes/Travel/Travel.helpers';
import Placeholder, { PlaceholderLine } from '@/components/Placeholder';
import Container from '@/components/Container';
import Box from '@/components/Box';
import {
  PromotionStatsLoaderProps,
  PromotionStatsProps,
  PromotionStatsCounter,
  PromotionStatsData,
} from './PromotionStats.types';
import './PromotionStats.css';

const PromotionStatsLoader: FunctionComponent<PromotionStatsLoaderProps> = ({
  className,
}): JSX.Element => {
  return (
    <Box className={className}>
      <Container noPadding>
        <div className="trv-promotion-stats">
          <Row>
            <Col md className="trv-promotion-stats-item">
              <p className="trv-promotion-stats-item-heading">
                Active promotions
              </p>
              <Placeholder>
                <PlaceholderLine />
              </Placeholder>
            </Col>
            <Col md className="trv-promotion-stats-item">
              <p className="trv-promotion-stats-item-heading">
                Pending promotions
              </p>
              <Placeholder>
                <PlaceholderLine />
              </Placeholder>
            </Col>
            <Col md className="trv-promotion-stats-item">
              <p className="trv-promotion-stats-item-heading">
                Completed promotions
              </p>
              <Placeholder>
                <PlaceholderLine />
              </Placeholder>
            </Col>
          </Row>
        </div>
      </Container>
    </Box>
  );
};

const PromotionStats: FunctionComponent<PromotionStatsProps> = ({
  promotions,
  loading,
  className,
}): JSX.Element => {
  if (loading) {
    return <PromotionStatsLoader className={className} />;
  }

  const promotionCounter: PromotionStatsCounter = {
    live: 0,
    pending: 0,
    complete: 0,
  };

  promotions.forEach((promotion: PromotionStatsData) => {
    const promotionStatus: OfferStatusType = getOfferStatus(
      promotion.from,
      promotion.end,
    );
    promotionCounter[promotionStatus] += 1;
  });

  return (
    <Box className={className}>
      <Container noPadding>
        <div className="trv-promotion-stats">
          <Row>
            <Col md className="trv-promotion-stats-item">
              <p className="trv-promotion-stats-item-heading">
                Active promotions
              </p>
              <p className="trv-promotion-stats-item-counter trv-promotion-stats-item-counter-green">
                {promotionCounter.live}
              </p>
            </Col>
            <Col md className="trv-promotion-stats-item">
              <p className="trv-promotion-stats-item-heading">
                Pending promotions
              </p>
              <p className="trv-promotion-stats-item-counter trv-promotion-stats-item-counter-orange">
                {promotionCounter.pending}
              </p>
            </Col>
            <Col md className="trv-promotion-stats-item">
              <p className="trv-promotion-stats-item-heading">
                Completed promotions
              </p>
              <p className="trv-promotion-stats-item-counter trv-promotion-stats-item-counter-purple">
                {promotionCounter.complete}
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </Box>
  );
};

export default PromotionStats;
