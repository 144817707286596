import moment from 'moment';
import 'moment-timezone';

export const MIN_DATE = '1970-01-01';

export const MAX_DATE = '2200-01-01';

export const CALENDAR_DATE_FORMAT = 'MM/DD/YYYY';

export const ISO_8601_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const dateToStr = (date: Date | string, format: string): string => {
  return date instanceof Date ? moment(date).format(format) : date;
};

export const limitDateToValidPeriodInZoneUTC = (
  date: Date | string,
  timezone: string = 'America/Chicago',
): string => {
  const strDate: string = dateToStr(date, 'YYYY-MM-DD');

  if (strDate.includes(MIN_DATE)) {
    return moment.tz(MIN_DATE, timezone).startOf('day').utc().format();
  }
  if (strDate.includes(MAX_DATE)) {
    return moment.tz(MAX_DATE, timezone).endOf('day').utc().format();
  }

  return strDate;
};

export const isValidDateInZone = (
  value: string,
  format: string[],
  timezone: string = 'America/Chicago',
): boolean => {
  return moment.tz(value, format, true, timezone).isValid();
};

export const currentDateInZone = (
  timezone: string = 'America/Chicago',
): moment.Moment => {
  return moment.tz(timezone);
};

export const formatDate = (
  date: any,
  format: string = 'L',
  locale: string = 'en',
): string => {
  return moment(date).locale(locale).format(format);
};

export const formatDateInZone = (
  date: Date | string,
  format: string = 'L',
  locale: string = 'en',
  timezone: string = 'America/Chicago',
): string => {
  return moment
    .tz(limitDateToValidPeriodInZoneUTC(date), timezone)
    .locale(locale)
    .format(format);
};

export const formatStartOfDayInZoneUTC = (
  date: string,
  timezone: string = 'America/Chicago',
): string => {
  return moment
    .tz(limitDateToValidPeriodInZoneUTC(date), timezone)
    .startOf('day')
    .utc()
    .format();
};

export const formatEndOfDayInZoneUTC = (
  date: string,
  timezone: string = 'America/Chicago',
): string => {
  return moment
    .tz(limitDateToValidPeriodInZoneUTC(date), timezone)
    .endOf('day')
    .utc()
    .format();
};

export const parseDateInZone = (
  date: string,
  format: string = 'L',
  locale: string = 'en',
  timezone: string = 'America/Chicago',
): Date | undefined => {
  const parsedDate: moment.Moment = moment.tz(
    date,
    format,
    locale,
    true,
    timezone,
  );

  if (parsedDate.isValid()) {
    return parsedDate.toDate();
  }

  return undefined;
};
