import React, { FunctionComponent, FormEvent } from 'react';
import { Form as BaseForm, FormProps as BaseFormProps } from 'react-bootstrap';
import './Form.css';

interface FormProps extends BaseFormProps {
  narrow?: boolean;
  disabled?: boolean;
  onSubmit?(e: FormEvent<HTMLFormElement>): void;
}

const getClassNames = (narrow?: boolean, disabled?: boolean): string => {
  let classNames = 'trv-form';

  if (narrow) {
    classNames += ' is-narrow';
  }

  if (disabled) {
    classNames += ' is-disabled';
  }

  return classNames;
};

const Form: FunctionComponent<FormProps> = ({
  narrow,
  disabled,
  children,
  ...props
}): JSX.Element => {
  return (
    <BaseForm {...props} className={getClassNames(narrow, disabled)} noValidate>
      {children}
    </BaseForm>
  );
};

export default Form;
