import React, { FunctionComponent } from 'react';
import './Placeholder.css';

interface PlaceholderProps {
  fluid?: boolean;
}

const Placeholder: FunctionComponent<PlaceholderProps> = ({
  fluid,
  children,
}): JSX.Element => {
  return (
    <div className={`trv-placeholder ${fluid ? 'trv-placeholder--fluid' : ''}`}>
      {children}
    </div>
  );
};

export default Placeholder;
