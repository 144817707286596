import gql from 'graphql-tag';

export const ADD_MARKETING_OFFER = gql`
  mutation addTravelMarketingOffer(
    $offerId: ID!
    $userId: String!
    $userName: String!
    $from: DateTime!
    $end: DateTime!
    $name: String!
    $title: String!
    $headline: String!
    $paragraph: String!
    $terms: String!
    $promotionMessage: String!
    $cashbackAmount: Int
    $cashbackCurrency: String!
    $cashbackPercentage: Int
    $minimumCashbackAmount: Int
    $minimumBookingTotalAmount: Int
    $qualifiers: [TravelMarketingOfferQualifier!]!
    $checkInBefore: Date
    $checkInAfter: Date
    $checkOutBefore: Date
    $checkOutAfter: Date
    $promoCode: String
    $maxUseCount: Int
    $testOffer: Boolean
  ) {
    addTravelMarketingOffer(
      offerId: $offerId
      userId: $userId
      userName: $userName
      from: $from
      end: $end
      name: $name
      title: $title
      headline: $headline
      paragraph: $paragraph
      terms: $terms
      promotionMessage: $promotionMessage
      cashbackAmount: $cashbackAmount
      cashbackCurrency: $cashbackCurrency
      cashbackPercentage: $cashbackPercentage
      minimumCashbackAmount: $minimumCashbackAmount
      minimumBookingTotalAmount: $minimumBookingTotalAmount
      qualifiers: $qualifiers
      checkInBefore: $checkInBefore
      checkInAfter: $checkInAfter
      checkOutBefore: $checkOutBefore
      checkOutAfter: $checkOutAfter
      promoCode: $promoCode
      maxUseCount: $maxUseCount
      testOffer: $testOffer
    ) {
      offer {
        offerId
        name
      }
    }
  }
`;
