import React, { FunctionComponent } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import Modal from '@/components/Modal';
import InAppPromoDescription from '@/assets/images/promotions/in-app-promo-description.png';
import InAppTitleAndHeadline from '@/assets/images/promotions/in-app-title-and-headline.png';
import InAppTitleExOne from '@/assets/images/promotions/in-app-title-ex-1.png';
import InAppTitleExTwo from '@/assets/images/promotions/in-app-title-ex-2.png';
import './InAppPromoDisplayModal.css';

interface InAppPromoDisplayModalProps {
  onHide?(): void;
  show?: boolean;
}

const InAppPromoDisplayModal: FunctionComponent<InAppPromoDisplayModalProps> = (
  props,
): JSX.Element => {
  return (
    <Modal
      heading="In-App Promo Display"
      size="xl"
      show
      animation={false}
      centered
      {...props}
    >
      <Row>
        <Col className="trv-in-app-promo-display-modal-item" sm={6} md>
          <div>In-App Title</div>
          <Image src={InAppTitleExOne} fluid />
        </Col>
        <Col className="trv-in-app-promo-display-modal-item" sm={6} md>
          <div>In-App Promo Description</div>
          <Image src={InAppPromoDescription} fluid />
        </Col>
        <Col className="trv-in-app-promo-display-modal-item" sm={6} md>
          <div>In-App Title & Headline</div>
          <Image src={InAppTitleAndHeadline} fluid />
        </Col>
        <Col className="trv-in-app-promo-display-modal-item" sm={6} md>
          <div>In-App Title</div>
          <Image src={InAppTitleExTwo} fluid />
        </Col>
      </Row>
    </Modal>
  );
};

export default InAppPromoDisplayModal;
