/* eslint-disable */
import React, { ErrorInfo, FunctionComponent } from 'react';
import { forEach, merge } from 'ramda';
import { toast, ToastOptions } from 'react-toastify';
import ToastContainer from '@/components/ToastContainer';
import { ToastType } from '@/components/ToastContainer/ToastContainer';
import { ModalError } from '@/helpers/getGraphQLError';

interface FormattedError {
  headerMessage: string;
  description: string;
}

interface Props {
  children: FunctionComponent;
}

interface State {
  hasError: boolean;
  error?: Error | undefined;
  errorInfo?: ErrorInfo | undefined;
  errors: FormattedError[];
}

const defaultErrorMessage: ModalError = {
  displayMessage: 'Something went wrong',
  message: 'Not enough data to display at this time.',
};

export const toastMapping = (options: ToastOptions, type: ToastType) =>
  forEach(({ displayMessage, message }) =>
    toast(
      () => <ToastContainer message={displayMessage} variant={type} />,
      merge(options, { hideProgressBar: true, closeButton: false }),
    ),
  );

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      errors: [],
    };
  }

  public static getDerivedStateFromError({ message }: Error) {
    // Update state so the next render will show the fallback UI.

    try {
      return {
        errors: JSON.parse(message),
        hasError: true,
      };
    } catch (e) {
      return {
        errors: [defaultErrorMessage],
        hasError: true,
      };
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // log the error here - put sumo here
    console.error(
      `componentDidCatch error boundary: ${error} - ${JSON.stringify(
        errorInfo,
      )}`,
    );
  }

  public render() {
    const { hasError, errors } = this.state;
    const { children } = this.props;

    if (hasError) {
      // toastMapping({})(errors);
      return null;
    }

    return children;
  }
}

export default ErrorBoundary;
