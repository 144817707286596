import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useQuery, ApolloError } from '@apollo/client';
import {
  ModalError,
  getGraphQLError,
  defaultErrorMessage,
} from '@/helpers/getGraphQLError';
import { toastMapping } from '@/components/utils/ErrorBoundary/ErrorBoundary';
import Container from '@/components/Container';
import Button from '@/components/Button';
import Breadcrumb from '@/components/Breadcrumb';
import NotFound from '@/scenes/NotFound';
import Loader from '@/components/Loader';
import PromotionForm from '@/scenes/Travel/scenes/Promotions/components/PromotionForm';
import PromotionStatus from './components/PromotionStatus';
import OfferUpdateLog from '@/scenes/Travel/components/OfferUpdateLog';
import EditLivePromotionForm from './components/EditLivePromotionForm';
import EditCompletedPromotionForm from './components/EditCompletedPromotionForm';
import {
  GET_MARKETING_OFFER,
  UPDATE_MARKETING_OFFER,
} from './EditPromotion.queries';
import { GET_OFFER_VERSIONS } from '@/scenes/Travel/components/OfferUpdateLog/OfferUpdateLog.queries';
import { triggerClick } from '@/helpers/dom';
import {
  PROMOTION_FORM_SUBMIT_BTN_ID,
  PromotionFormData,
} from '@/scenes/Travel/scenes/Promotions/components/PromotionForm/PromotionForm.types';
import {
  EditPromotionParams,
  EditPromotionResponse,
} from './EditPromotion.types';
import { OfferStatusType } from '@/scenes/Travel/Travel.types';
import { getOfferStatus } from '@/scenes/Travel/Travel.helpers';
import { transformPromotionResponseToFormData } from './EditPromotion.transforms';
import './EditPromotion.css';

// The specs dictates that depending on the status of the offer (LIVE, PENDING
// or COMPLETE) we need to allow different kind of fields to be editable.
const renderEditPromotionForm = (
  promotion: EditPromotionResponse,
  setSubmitting: Function,
  setSaved: Function,
): JSX.Element => {
  const promotionStatus: OfferStatusType = getOfferStatus(
    promotion.from,
    promotion.end,
  );
  const initialValues: PromotionFormData =
    transformPromotionResponseToFormData(promotion);
  const errorMessage = 'Updating promotion failed';

  switch (promotionStatus) {
    case OfferStatusType.PENDING:
      return (
        <PromotionForm
          validateOnMount={false}
          initialValues={initialValues}
          mutation={UPDATE_MARKETING_OFFER}
          errorMessage={errorMessage}
          onLoading={(loading) => setSubmitting(loading)}
          onSave={() => setSaved(true)}
        />
      );
    case OfferStatusType.LIVE:
      return (
        <EditLivePromotionForm
          initialValues={initialValues}
          mutation={UPDATE_MARKETING_OFFER}
          errorMessage={errorMessage}
          onLoading={(loading) => setSubmitting(loading)}
          onSave={() => setSaved(true)}
        />
      );
    case OfferStatusType.COMPLETE:
      return <EditCompletedPromotionForm initialValues={initialValues} />;
    default:
      throw new Error(`Could not get promotion form for "${promotionStatus}"`);
  }
};

const EditPromotion: FunctionComponent = (): JSX.Element => {
  const params = useParams<EditPromotionParams>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);

  const {
    loading: loadingPromos,
    error: getPromosError,
    data: promoData,
    refetch: refetchPromos,
  } = useQuery(GET_MARKETING_OFFER, {
    variables: { offerIds: [params.id] },
    fetchPolicy: 'network-only',
  });

  const {
    loading: loadingVersions,
    error: getVersionsError,
    data: versionData,
    refetch: refetchVersions,
  } = useQuery(GET_OFFER_VERSIONS, {
    variables: { offerId: params.id },
    fetchPolicy: 'network-only',
  });

  const error: ApolloError | undefined = getPromosError || getVersionsError;

  useEffect(() => {
    if (error) {
      const formattedError: ModalError[] = getGraphQLError(
        defaultErrorMessage,
        false,
        error,
      );
      toastMapping({}, 'error')(formattedError);
    }
  }, [error]);

  useEffect(() => {
    if (saved) {
      toastMapping(
        {},
        'success',
      )([
        {
          displayMessage: 'All changes saved successfully',
          message: '',
        },
      ]);
      refetchPromos();
      refetchVersions();
      setSaved(false);
    }
  }, [saved]);

  const loading: boolean = loadingPromos || loadingVersions;

  if (loading || error) {
    return <Loader />;
  }

  const promotion: any = promoData.travelMarketingOffers.offers[0];

  if (!promotion) {
    return <NotFound />;
  }

  const allowEditing: boolean =
    getOfferStatus(promotion.from, promotion.end) !== OfferStatusType.COMPLETE;

  return (
    <>
      <Breadcrumb
        link={{ label: 'All Promos', href: '/' }}
        currentPage={promotion.name || promotion.title}
      >
        {allowEditing && (
          <Button
            onClick={() => triggerClick(PROMOTION_FORM_SUBMIT_BTN_ID)}
            loading={submitting}
          >
            Save Changes
          </Button>
        )}
      </Breadcrumb>
      <Container>
        <Row>
          <Col className="trv-edit-promotion-status">
            <PromotionStatus promotion={promotion} />
          </Col>
        </Row>
        <Row>
          <Col lg={7} className="trv-edit-promotion-form">
            {renderEditPromotionForm(promotion, setSubmitting, setSaved)}
          </Col>
          <Col lg={5}>
            <OfferUpdateLog
              offerUpdates={versionData.travelOfferVersions.versions}
              offerType="promotion"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditPromotion;
