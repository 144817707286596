import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { currentDateInZone } from '@/helpers/date';
import { PromotionFormData } from '@/scenes/Travel/scenes/Promotions/components/PromotionForm/PromotionForm.types';

export const getEmptyFormValues = (): PromotionFormData => {
  const today: moment.Moment = currentDateInZone();
  const startDate: string = today.format();
  const endDate: string = today.add(6, 'months').format();

  return {
    offerId: uuidv4(),
    startDate,
    endDate,
    version: '',
    checkInBefore: '',
    checkInAfter: '',
    checkOutBefore: '',
    checkOutAfter: '',
    promoName: '',
    promoCode: '',
    cashbackBonusRule: 'cashbackAmount',
    cashbackAmount: '',
    cashbackMultiplier: '',
    minimumCashbackAmount: '',
    minimumBookingTotalAmount: '',
    offerType: 'promoCodeOffer',
    userSegment: 'allBookings',
    testPromo: 'yes',
    maxUsage: '',
    inAppTitle: '',
    inAppHeadline: '',
    inAppConfirmation: '',
    inAppPromotionDescription: '',
  };
};
