import {
  ISO_8601_DATE_FORMAT,
  isValidDateInZone,
  formatDateInZone,
} from '@/helpers/date';

export const formatTimestamp = (date: string): string => {
  return formatDateInZone(date, 'MMMM Do YYYY, h:mm:ss');
};

// Probably not the best way to go about this, but this works
// for now because we are not translating things at the backend.
export const formatFieldLabel = (value: string): string => {
  switch (value) {
    case 'from':
      return 'Start Date';
    case 'end':
      return 'End Date';
    case 'checkInAfter':
      return 'Check In After Date';
    case 'checkInBefore':
      return 'Check In Before Date';
    case 'checkOutAfter':
      return 'Check Out After Date';
    case 'checkOutBefore':
      return 'Check Out Before Date';
    case 'name':
      return 'Promo Name';
    case 'promoCode':
      return 'Promo Code';
    case 'cashbackAmount':
      return 'Cash Back Amount';
    case 'minimumCashbackAmount':
      return 'Minimum Cash Back Amount';
    case 'minimumBookingTotalAmount':
      return 'Minimum Booking Total Amount';
    case 'cashbackPercentage':
      return 'Cash Back Multiplier';
    case 'testOffer':
      return 'Test Promo';
    case 'maxUseCount':
      return 'Max Usage';
    case 'title':
      return 'In-App Title';
    case 'headline':
      return 'In-App Headline';
    case 'paragraph':
      return 'In-App Confirmation';
    case 'promotionMessage':
      return 'In-App Promotion Description';
    case 'payoutDays':
      return 'Payout Days';
    case 'minimumCommissionPercentage':
      return 'Minimum Commission Multiplier';
    default:
      return value;
  }
};

export const formatUserSegmentValue = (value: string | null): string => {
  return value && value.includes('FIRST_BOOKING_ONLY')
    ? 'First Time Bookers'
    : 'All Bookings';
};

export const formatOfferTypeValue = (value: string | null): string => {
  return value && value.includes('PROMO_CODE_ENTRY')
    ? 'Promo Code Offer'
    : 'Marketing Offer';
};

export const formatFieldValue = (value: string | null): string => {
  if (!value) {
    return '<no value>';
  }

  // Updates on the date fields needs special formatting
  if (isValidDateInZone(value, ['YYYY-MM-DD', ISO_8601_DATE_FORMAT])) {
    return formatDateInZone(value, 'LL');
  }

  return value;
};
