import {
  CommissionSplitFormData,
  CommissionSplitQualifier,
} from '@/scenes/Travel/scenes/CommissionSplits/components/CommissionSplitForm/CommissionSplitForm.types';
import { EditCommissionSplitResponse } from './EditCommissionSplit.types';

const numberToStr = (value: number | null | undefined): string => {
  return value !== (undefined || null) ? `${value}` : '';
};

const transformQualifierToUserSegment = (
  qualifier: CommissionSplitQualifier,
): string => {
  return qualifier === 'FIRST_BOOKING_ONLY' ? 'firstTimeBookers' : '';
};

export const transformCommissionSplitResponseToFormData = (
  commissionSplit: EditCommissionSplitResponse,
): CommissionSplitFormData => {
  return {
    offerId: commissionSplit.offerId,
    startDate: commissionSplit.from,
    endDate: commissionSplit.end,
    version: commissionSplit.version,
    cashbackPercentage: numberToStr(commissionSplit.cashbackPercentage),
    userSegment: transformQualifierToUserSegment(commissionSplit.qualifier),
    minimumCommissionPercentage: numberToStr(
      commissionSplit.minimumCommissionPercentage,
    ),
    payoutDays: numberToStr(commissionSplit.payoutDays),
  };
};
