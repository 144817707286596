import { AuthState } from '@okta/okta-auth-js';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

let authState: AuthState | null;

export const updateAuthState = (newAuth: AuthState | null) => {
  authState = newAuth;
};

const httpLink = createHttpLink({
  uri: process.env.API_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = authState?.accessToken?.accessToken;
  return {
    headers: {
      ...headers,
      Accept: 'application/json',
      'x-api-key': process.env.API_KEY || '',
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});
