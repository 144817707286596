import gql from 'graphql-tag';

export const GET_COMMISSION_OFFER = gql`
  query travelCommissionOffers($offerIds: [ID!]) {
    travelCommissionOffers(offerIds: $offerIds) {
      offers {
        offerId
        version
        from
        end
        cashbackPercentage
        payoutDays
        minimumCommissionPercentage
        qualifier
      }
    }
  }
`;

export const UPDATE_COMMISSION_OFFER = gql`
  mutation updateTravelCommissionOffer(
    $offerId: ID!
    $version: String!
    $userId: String!
    $userName: String!
    $from: DateTime
    $end: DateTime
    $cashbackPercentage: Int
    $payoutDays: Int
    $minimumCommissionPercentage: Int
    $qualifier: TravelCommissionOfferQualifier
  ) {
    updateTravelCommissionOffer(
      offerId: $offerId
      version: $version
      userId: $userId
      userName: $userName
      from: $from
      end: $end
      cashbackPercentage: $cashbackPercentage
      payoutDays: $payoutDays
      minimumCommissionPercentage: $minimumCommissionPercentage
      qualifier: $qualifier
    ) {
      offer {
        offerId
      }
    }
  }
`;
