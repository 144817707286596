import React, { FunctionComponent, useState } from 'react';
import Breadcrumb from '@/components/Breadcrumb';
import Button from '@/components/Button';
import Container from '@/components/Container';
import CommissionSplitForm from '@/scenes/Travel/scenes/CommissionSplits/components/CommissionSplitForm';
import { COMMISSION_SPLIT_FORM_SUBMIT_BTN_ID } from '@/scenes/Travel/scenes/CommissionSplits/components/CommissionSplitForm/CommissionSplitForm.types';
import { triggerClick } from '@/helpers/dom';
import { getEmptyFormValues } from './CreateCommissionSplit.helpers';
import { ADD_COMMISSION_OFFER } from './CreateCommissionSplit.queries';

const CreateCommissionSplit: FunctionComponent = (): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  return (
    <>
      <Breadcrumb
        link={{ label: 'All Promos', href: '/' }}
        currentPage="Create Commission Split"
      >
        <Button
          onClick={() => triggerClick(COMMISSION_SPLIT_FORM_SUBMIT_BTN_ID)}
          loading={submitting}
        >
          Create Split
        </Button>
      </Breadcrumb>
      <Container>
        <CommissionSplitForm
          validateOnMount
          initialValues={getEmptyFormValues()}
          mutation={ADD_COMMISSION_OFFER}
          errorMessage="Failed to create a new commission split"
          onLoading={setSubmitting}
          redirectAfterSave={{
            pathname: '/',
            state: {
              flashMessage: 'Commission split created successfully!',
            },
          }}
        />
      </Container>
    </>
  );
};

export default CreateCommissionSplit;
