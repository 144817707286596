import React, { FunctionComponent, useState } from 'react';
import PromotionTable from '../PromotionTable';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import SearchBar from '@/components/SearchBar';
import './PromotionSearch.css';

interface PromotionSearchProps {
  promotions: any[];
  loading: boolean;
  className?: string;
}

const PromotionSearch: FunctionComponent<PromotionSearchProps> = ({
  promotions,
  loading,
  className,
}): JSX.Element => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  return (
    <div className={className}>
      <SearchBar
        className="trv-promotion-search-bar"
        placeholder="Search Promotions"
        size="lg"
        onChange={(e) => setSearchKeyword(e.target.value)}
        value={searchKeyword}
      />
      <div className="trv-promotion-search-header">
        <h1>Promotions</h1>
        <Button href="/promotions/new" icon={<Icon type="add" color="#fff" />}>
          New Promo
        </Button>
      </div>
      <PromotionTable
        promotions={promotions}
        searchKeyword={searchKeyword}
        loading={loading}
      />
    </div>
  );
};

export default PromotionSearch;
