import React, { FunctionComponent } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import './Breadcrumb.css';

interface BreadcrumbProps {
  link: {
    label: string;
    href: string;
  };
  currentPage: string;
}

const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({
  children,
  link,
  currentPage,
}): JSX.Element => {
  return (
    <nav className="trv-breadcrumb-container">
      <Container fluid>
        <div className="trv-breadcrumb">
          <div className="trv-breadcrumb-item">
            <span className="trv-breadcrumb-icon">
              <Icon type="keyboardBack" size="1rem" color="#007bff" />
            </span>
          </div>
          <div className="trv-breadcrumb-item">
            <Link to={link.href}>{link.label}</Link>
          </div>
          <div className="trv-breadcrumb-item">
            <span className="trv-breadcrumb-icon">
              <Icon type="chevronRight" size="1rem" color="#4a4a4a" />
            </span>
          </div>
          <div className="trv-breadcrumb-item trv-breadcrumb-item--overflow">
            {currentPage}
          </div>
          <div className="trv-breadcrumb-item trv-breadcrumb-action">
            {children}
          </div>
        </div>
      </Container>
    </nav>
  );
};

export default Breadcrumb;
