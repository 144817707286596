import React, { FunctionComponent } from 'react';
import { PromotionFormData } from '@/scenes/Travel/scenes/Promotions/components/PromotionForm/PromotionForm.types';
import {
  formatMaxUsage,
  formatCasbackBonusRule,
  formatOfferType,
  formatUserSegment,
  strToNumberOrNull,
} from './FormPromotionRules.helpers';
import { formatCashbackAmount } from '@/scenes/Travel/scenes/Promotions/Promotions.helpers';
import { PromotionCashback } from '@/scenes/Travel/scenes/Promotions/Promotions.types';

interface FormPromotionRulesProps {
  initialValues: PromotionFormData;
}

const FormPromotionRules: FunctionComponent<FormPromotionRulesProps> = ({
  initialValues,
}): JSX.Element => {
  const {
    cashbackAmount,
    cashbackMultiplier,
    minimumCashbackAmount,
    cashbackBonusRule,
    offerType,
    userSegment,
    maxUsage,
    testPromo,
  } = initialValues;

  const promotionCashback: PromotionCashback = {
    cashbackAmount: strToNumberOrNull(cashbackAmount),
    cashbackPercentage: strToNumberOrNull(cashbackMultiplier),
    minimumCashbackAmount: strToNumberOrNull(minimumCashbackAmount),
  };

  return (
    <>
      <h6>Promotion Rules</h6>
      <ul className="mb-4">
        <li>{formatCashbackAmount(promotionCashback)}</li>
        <li>{formatCasbackBonusRule(cashbackBonusRule)}</li>
        <li>{formatOfferType(offerType)}</li>
        <li>{formatUserSegment(userSegment)}</li>
        <li>{formatMaxUsage(maxUsage)}</li>
        {testPromo === 'yes' && <li>Test Promo</li>}
      </ul>
    </>
  );
};

export default FormPromotionRules;
