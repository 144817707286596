import React, { FunctionComponent, useState } from 'react';
import Breadcrumb from '@/components/Breadcrumb';
import Button from '@/components/Button';
import Container from '@/components/Container';
import PromotionForm from '@/scenes/Travel/scenes/Promotions/components/PromotionForm';
import { triggerClick } from '@/helpers/dom';
import { PROMOTION_FORM_SUBMIT_BTN_ID } from '@/scenes/Travel/scenes/Promotions/components/PromotionForm/PromotionForm.types';
import { getEmptyFormValues } from './CreatePromotion.helpers';
import { ADD_MARKETING_OFFER } from './CreatePromotion.queries';

const CreatePromotion: FunctionComponent = (): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  return (
    <>
      <Breadcrumb
        link={{ label: 'All Promos', href: '/' }}
        currentPage="Create Promo"
      >
        <Button
          onClick={() => triggerClick(PROMOTION_FORM_SUBMIT_BTN_ID)}
          loading={submitting}
        >
          Create Promo
        </Button>
      </Breadcrumb>
      <Container>
        <PromotionForm
          validateOnMount
          initialValues={getEmptyFormValues()}
          mutation={ADD_MARKETING_OFFER}
          errorMessage="Failed to create a new promotion"
          onLoading={setSubmitting}
          redirectAfterSave={{
            pathname: '/',
            state: {
              flashMessage: 'Promotion created successfully!',
            },
          }}
        />
      </Container>
    </>
  );
};

export default CreatePromotion;
