import React, { FunctionComponent, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
  getGraphQLError,
  defaultErrorMessage,
  ModalError,
} from '@/helpers/getGraphQLError';
import { toastMapping } from '@/components/utils/ErrorBoundary/ErrorBoundary';
import DateFormatNotification from '@/scenes/Travel/components//DateFormatNotification';
import PromotionStats from './components/PromotionStats';
import PromotionSearch from './components/PromotionSearch';
import { GET_MARKETING_OFFERS } from './Promotions.queries';
import './Promotions.css';

const Promotions: FunctionComponent = (): JSX.Element => {
  const { loading, error, data } = useQuery(GET_MARKETING_OFFERS);

  useEffect(() => {
    if (error) {
      const formattedError: ModalError[] = getGraphQLError(
        defaultErrorMessage,
        false,
        error,
      );
      toastMapping({}, 'error')(formattedError);
    }
  }, [error]);

  const displayLoaders: boolean = loading || !!error;
  const promotions: any[] = displayLoaders
    ? []
    : data.travelMarketingOffers.offers;

  // Promotions with "EVERY_BOOKING_SPECIAL_BONUS" is filtered away from the results;
  // This line can be removed later if `filteredQualifiers` is used when doing
  // the `GET_MARKETING_OFFERS` query against the GQL API.
  const filteredPromotions: any[] = promotions.filter((promotion) => {
    return !promotion.qualifiers.includes('EVERY_BOOKING_SPECIAL_BONUS');
  });

  return (
    <>
      <h1 className="trv-promotions-heading">Travel Promotions Dashboard</h1>
      <PromotionStats
        className="trv-promotions-stats"
        promotions={filteredPromotions}
        loading={displayLoaders}
      />
      <PromotionSearch
        promotions={filteredPromotions}
        loading={displayLoaders}
      />
      <DateFormatNotification />
    </>
  );
};

export default Promotions;
