import { PromotionCashback } from './Promotions.types';

const centsToDollars = (cents: number): number => {
  return cents / 100.0;
};

export const formatCashbackAmount = (
  promotionCashback: PromotionCashback,
): string => {
  const { cashbackAmount, cashbackPercentage, minimumCashbackAmount } =
    promotionCashback;

  if (cashbackAmount) {
    return `$${centsToDollars(cashbackAmount)}`;
  }
  if (cashbackPercentage && minimumCashbackAmount) {
    return `${cashbackPercentage}% (min: $${centsToDollars(
      minimumCashbackAmount,
    )})`;
  }
  if (cashbackPercentage) {
    return `${cashbackPercentage}%`;
  }
  return `<data is corrupted>`;
};
