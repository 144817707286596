import gql from 'graphql-tag';

export const ADD_COMMISSION_OFFER = gql`
  mutation addTravelCommissionOffer(
    $offerId: ID!
    $userId: String!
    $userName: String!
    $from: DateTime!
    $end: DateTime!
    $cashbackPercentage: Int!
    $payoutDays: Int!
    $minimumCommissionPercentage: Int!
    $qualifier: TravelCommissionOfferQualifier
  ) {
    addTravelCommissionOffer(
      offerId: $offerId
      userId: $userId
      userName: $userName
      from: $from
      end: $end
      cashbackPercentage: $cashbackPercentage
      payoutDays: $payoutDays
      minimumCommissionPercentage: $minimumCommissionPercentage
      qualifier: $qualifier
    ) {
      offer {
        offerId
      }
    }
  }
`;
