import React, { FunctionComponent, useState } from 'react';
import moment from 'moment';
import { omit } from 'ramda';
import TableHeadCell, {
  TableColumnSortOrder,
} from './components/TableHeadCell';
import './Table.css';

type TableColumnSortingMethod = 'alphanumeric' | 'datetime';

export interface TableColumn {
  title: string;
  field: string;
  sortType?: TableColumnSortingMethod;
  sortingValue?(tableCellValue: any): number | string;
  format?(tableCellValue: any): string | JSX.Element;
}

export interface TableRow {
  // `meta` is a special property which value is not rendered
  // in a typical table cell. It's useful in cases when we
  // want to do things like row clicks.
  meta?: any;
  [key: string]: any;
}

interface TableProps {
  columns: TableColumn[];
  rows: TableRow[];
  onClickRow(tableRow: TableRow): void;
}

const findColumnByField = (
  columns: TableColumn[],
  field: string,
): TableColumn => {
  const tableColumn: TableColumn | undefined = columns.find(
    (column) => column.field === field,
  );

  if (!tableColumn) {
    throw new Error(
      `Could not find table column for "${field}", did you set columns and rows properly?`,
    );
  }

  return tableColumn;
};

const formatTableCell = (
  field: string,
  row: TableRow,
  columns: TableColumn[],
): string | JSX.Element => {
  const tableColumnForField: TableColumn = findColumnByField(columns, field);
  const tableCellValue: any = row[field];

  // Formatting table cell with special marks or jsx is optional functionality (See: TableColumn -interface)
  return tableColumnForField.format
    ? tableColumnForField.format(tableCellValue)
    : tableCellValue;
};

// Changing the sorting value is optional functionality (See: TableColumn -interface)
const getTableColumnSortingValue = (
  tableRow: TableRow,
  tableColumn: TableColumn,
): string => {
  return tableColumn.sortingValue
    ? tableColumn.sortingValue(tableRow[tableColumn.field])
    : tableRow[tableColumn.field];
};

const sortAlphanumerically = (
  a: TableRow,
  b: TableRow,
  tableColumn: TableColumn,
): number => {
  const aSortingValue: string = getTableColumnSortingValue(a, tableColumn);
  const bSortingValue: string = getTableColumnSortingValue(b, tableColumn);

  if (aSortingValue > bSortingValue) {
    return 1;
  }
  if (bSortingValue > aSortingValue) {
    return -1;
  }

  return 0;
};

const sortByDatetime = (
  a: TableRow,
  b: TableRow,
  tableColumn: TableColumn,
): number => {
  const aSortingValue: string = getTableColumnSortingValue(a, tableColumn);
  const bSortingValue: string = getTableColumnSortingValue(b, tableColumn);

  return moment(aSortingValue).diff(moment(bSortingValue));
};

// Changing the sorting method is optional functionality (See: TableColumn -interface)
const getTableColumnComparer = (tableColumn: TableColumn) => {
  switch (tableColumn.sortType) {
    case 'datetime':
      return sortByDatetime;
    default:
      return sortAlphanumerically; // The default sorting method
  }
};

const sortRows = (
  rows: TableRow[],
  columns: TableColumn[],
  sortOrder: TableColumnSortOrder,
  sortByField: string,
): TableRow[] => {
  if (!sortByField) {
    return rows;
  }

  const tableColumnSortOrder: number = sortOrder === 'asc' ? 1 : -1;
  const tableColumnUsedForSorting: TableColumn = findColumnByField(
    columns,
    sortByField,
  );
  const tableColumnComparer = getTableColumnComparer(tableColumnUsedForSorting);

  return rows.sort(
    (a, b) =>
      tableColumnComparer(a, b, tableColumnUsedForSorting) *
      tableColumnSortOrder,
  );
};

const Table: FunctionComponent<TableProps> = ({
  columns,
  rows,
  onClickRow,
}): JSX.Element => {
  const [sortOrder, setSortOrder] = useState<TableColumnSortOrder>('asc');
  const [sortByField, setSortByField] = useState<string>('');

  const handleSort = (field: string): void => {
    const clickedFieldIsSortedAsc: boolean =
      field === sortByField && sortOrder === 'asc';
    const nextSortOrder: TableColumnSortOrder = clickedFieldIsSortedAsc
      ? 'desc'
      : 'asc';
    setSortOrder(nextSortOrder);
    setSortByField(field);
  };

  return (
    <div className="trv-table-container">
      <table className="trv-table trv-table--hoverable">
        <thead>
          <tr>
            {columns.map((column, i) => (
              <TableHeadCell
                key={i}
                title={column.title}
                field={column.field}
                sortOrder={sortOrder}
                sortByField={sortByField}
                onSort={handleSort}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {sortRows(rows, columns, sortOrder, sortByField).map((row, i) => (
            <tr key={i} onClick={() => onClickRow(row)}>
              {Object.keys(omit(['meta'], row)).map((field, j) => (
                <td key={j}>{formatTableCell(field, row, columns)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="trv-table-pagination" />
    </div>
  );
};

export default Table;
