import React, { FunctionComponent } from 'react';
import Placeholder, {
  PlaceholderParagraph,
  PlaceholderLine,
} from '@/components/Placeholder';
import './TableLoader.css';

const TableLoader: FunctionComponent = (): JSX.Element => {
  return (
    <div className="trv-table-loader">
      <Placeholder fluid>
        <PlaceholderParagraph>
          <PlaceholderLine />
          <PlaceholderLine />
          <PlaceholderLine />
          <PlaceholderLine />
        </PlaceholderParagraph>
      </Placeholder>
    </div>
  );
};

export default TableLoader;
