import React, { FunctionComponent } from 'react';
import './ToastContainer.css';

export type ToastType = 'success' | 'error';

interface ToastContainerProps {
  message: string;
  variant: ToastType;
}

const getTitleForVariant = (variant: ToastType): string => {
  return variant === 'success' ? 'Well done!' : 'Oh snap!';
};

const ToastContainer: FunctionComponent<ToastContainerProps> = ({
  message,
  variant,
}): JSX.Element => {
  return (
    <div className={`trv-toast-${variant}`}>
      <div className="trv-toast-header">
        <strong>{getTitleForVariant(variant)}</strong>
        <small>just now</small>
      </div>
      <div className="trv-toast-body">{message}</div>
    </div>
  );
};

export default ToastContainer;
