import React, { FunctionComponent } from 'react';
import './TableHeadCell.css';

export type TableColumnSortOrder = 'asc' | 'desc';

export interface TableHeadCellProps {
  title: string;
  field: string;
  sortOrder: TableColumnSortOrder;
  sortByField: string;
  onSort(field: string): void;
}

const TableHeadCell: FunctionComponent<TableHeadCellProps> = ({
  title,
  field,
  sortOrder,
  sortByField,
  onSort,
}): JSX.Element => {
  const ascending: boolean = field === sortByField && sortOrder === 'asc';
  const sortOrderSelf: TableColumnSortOrder = ascending ? 'desc' : 'asc';

  return (
    <th>
      <span
        onClick={() => onSort(field)}
        className={`trv-table-head-cell trv-table-head-cell-${sortOrderSelf}`}
        role="presentation"
      >
        {title}
      </span>
    </th>
  );
};

export default TableHeadCell;
