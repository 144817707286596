import React, { FunctionComponent, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toastMapping } from '../utils/ErrorBoundary/ErrorBoundary';

const Flash: FunctionComponent = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // @ts-ignore
    const { flashMessage } = location.state || { flashMessage: undefined };

    if (flashMessage) {
      toastMapping(
        {},
        'success',
      )([
        {
          displayMessage: flashMessage,
          message: '',
        },
      ]);

      history.replace('', null); // Clears the flash message after reading
    }
  });

  return <></>;
};

export default Flash;
