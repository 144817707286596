import gql from 'graphql-tag';

export const GET_MARKETING_OFFERS = gql`
  query travelMarketingOffers(
    $requiredQualifiers: [TravelMarketingOfferQualifier!]
  ) {
    travelMarketingOffers(requiredQualifiers: $requiredQualifiers) {
      offers {
        offerId
        from
        end
        name
        title
        cashbackAmount
        cashbackPercentage
        minimumCashbackAmount
        promoCode
        qualifiers
      }
    }
  }
`;
