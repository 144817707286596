import { isEmpty } from 'ramda';
import {
  PromotionRequestParams,
  PromotionFormData,
  PromotionQualifier,
} from './PromotionForm.types';
import {
  formatDateInZone,
  formatStartOfDayInZoneUTC,
  formatEndOfDayInZoneUTC,
} from '@/helpers/date';

const formatCheckInOutDate = (date: string): string => {
  return formatDateInZone(date, 'YYYY-MM-DD');
};

const transformUserSegmentAndOfferTypeToQualifiers = (
  offerType: string,
  userSegment: string,
): PromotionQualifier[] => {
  const qualifiers: PromotionQualifier[] = [
    userSegment === 'allBookings' ? 'EVERY_BOOKING' : 'FIRST_BOOKING_ONLY',
  ];

  if (offerType === 'promoCodeOffer') {
    qualifiers.push('PROMO_CODE_ENTRY');
  }

  return qualifiers;
};

export const transformPromotionFormDataToParams = (
  values: PromotionFormData,
): PromotionRequestParams => {
  const params: PromotionRequestParams = {
    offerId: values.offerId,
    from: formatStartOfDayInZoneUTC(values.startDate),
    end: formatEndOfDayInZoneUTC(values.endDate),
    name: values.promoName,
    title: values.inAppTitle,
    headline: values.inAppHeadline,
    paragraph: values.inAppConfirmation,
    terms: 'Terms and conditions',
    promotionMessage: values.inAppPromotionDescription,
    cashbackCurrency: 'USD',
    qualifiers: transformUserSegmentAndOfferTypeToQualifiers(
      values.offerType,
      values.userSegment,
    ),
    promoCode: values.promoCode,
    maxUseCount: parseInt(values.maxUsage, 10),
    testOffer: values.testPromo === 'yes',
    cashbackAmount: null,
    cashbackPercentage: null,
    minimumCashbackAmount: null,
    minimumBookingTotalAmount: null,
  };

  if (!isEmpty(values.version)) {
    params.version = values.version;
  }

  if (!isEmpty(values.checkInAfter) && !isEmpty(values.checkInBefore)) {
    params.checkInAfter = formatCheckInOutDate(values.checkInAfter);
    params.checkInBefore = formatCheckInOutDate(values.checkInBefore);
  }

  if (!isEmpty(values.checkOutAfter) && !isEmpty(values.checkOutBefore)) {
    params.checkOutAfter = formatCheckInOutDate(values.checkOutAfter);
    params.checkOutBefore = formatCheckInOutDate(values.checkOutBefore);
  }

  if (values.cashbackBonusRule === 'cashbackAmount') {
    params.cashbackAmount = parseInt(values.cashbackAmount, 10);
  } else {
    params.cashbackPercentage = parseInt(values.cashbackMultiplier, 10);

    if (!isEmpty(values.minimumCashbackAmount)) {
      params.minimumCashbackAmount = parseInt(values.minimumCashbackAmount, 10);
    }
  }

  if (
    values.offerType === 'promoCodeOffer' &&
    !isEmpty(values.minimumBookingTotalAmount)
  ) {
    params.minimumBookingTotalAmount = parseInt(
      values.minimumBookingTotalAmount,
      10,
    );
  }

  return params;
};
