import React, { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { formatDateInZone } from '@/helpers/date';
import Box from '@/components/Box';
import Badge from '@/components/Badge';
import Container from '@/components/Container';
import { formatCashbackAmount } from '@/scenes/Travel/scenes/Promotions/Promotions.helpers';
import {
  getOfferStatus,
  getBadgeVariant,
} from '@/scenes/Travel/Travel.helpers';
import { OfferStatusType } from '@/scenes/Travel/Travel.types';
import { PromotionStatusProps } from './PromotionStatus.types';
import './PromotionStatus.css';

const PromotionStatus: FunctionComponent<PromotionStatusProps> = ({
  promotion,
}): JSX.Element => {
  const promotionStatus: OfferStatusType = getOfferStatus(
    promotion.from,
    promotion.end,
  );

  return (
    <Box>
      <Container noPadding>
        <Row>
          <Col xl={5} className="trv-promotion-status-header">
            <h1>{promotion.name || promotion.title}</h1>
            <Badge variant={getBadgeVariant(promotionStatus)}>
              {promotionStatus}
            </Badge>
          </Col>
          <Col xl={7}>
            <Row className="trv-promotion-status-panel">
              <Col lg className="trv-promotion-status-panel-item">
                <p className="trv-promotion-status-panel-item-label">
                  Promo code
                </p>
                <p className="trv-promotion-status-panel-item-value is-purple">
                  {promotion.promoCode || '<no value>'}
                </p>
              </Col>
              <Col lg className="trv-promotion-status-panel-item">
                <p className="trv-promotion-status-panel-item-label">Amount</p>
                <p className="trv-promotion-status-panel-item-value is-purple">
                  {formatCashbackAmount(promotion)}
                </p>
              </Col>
              <Col lg className="trv-promotion-status-panel-item">
                <p className="trv-promotion-status-panel-item-label">Ends</p>
                <p className="trv-promotion-status-panel-item-value is-purple">
                  {formatDateInZone(promotion.end, 'll')}
                </p>
              </Col>
              <Col lg className="trv-promotion-status-panel-item">
                <p className="trv-promotion-status-panel-item-label">
                  Redemptions
                </p>
                <p className="trv-promotion-status-panel-item-value">
                  {promotion.redemptionCount}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

export default PromotionStatus;
