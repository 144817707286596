import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { currentDateInZone } from '@/helpers/date';
import { CommissionSplitFormData } from '@/scenes/Travel/scenes/CommissionSplits/components/CommissionSplitForm/CommissionSplitForm.types';

export const getEmptyFormValues = (): CommissionSplitFormData => {
  const today: moment.Moment = currentDateInZone();
  const startDate: string = today.format();
  const endDate: string = today.add(6, 'months').format();

  return {
    offerId: uuidv4(),
    startDate,
    endDate,
    version: '',
    cashbackPercentage: '',
    minimumCommissionPercentage: '',
    payoutDays: '',
    userSegment: '',
  };
};
