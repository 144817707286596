import React, { FunctionComponent, MouseEvent } from 'react';
import './IconButton.css';

interface IconButtonProps {
  onClick?(e: MouseEvent<HTMLButtonElement>): void;
}

const IconButton: FunctionComponent<IconButtonProps> = ({
  children,
  ...props
}): JSX.Element => {
  return (
    <button type="button" className="trv-icon-button" {...props}>
      <span>{children}</span>
    </button>
  );
};

export default IconButton;
