import moment from 'moment';
import { BadgeType } from '@/components/Badge/Badge';
import { OfferStatusType } from './Travel.types';

export const getBadgeVariant = (offerStatus: OfferStatusType): BadgeType => {
  switch (offerStatus) {
    case OfferStatusType.LIVE:
      return 'green';
    case OfferStatusType.PENDING:
      return 'orange';
    case OfferStatusType.COMPLETE:
      return 'purple';
    default:
      throw new Error(`Could not get badge variant for "${offerStatus}"`);
  }
};

export const getOfferStatus = (from: string, end: string): OfferStatusType => {
  let status: OfferStatusType = OfferStatusType.LIVE;
  const now: moment.Moment = moment.utc();

  if (now.isAfter(end)) {
    status = OfferStatusType.COMPLETE;
  } else if (now.isBefore(from)) {
    status = OfferStatusType.PENDING;
  }

  return status;
};
