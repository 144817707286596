import React, { FunctionComponent } from 'react';
import { Alert } from 'react-bootstrap';
import './FormAlert.css';

interface FormAlertProps {
  title?: string;
  message: string;
  variant: 'danger' | 'primary';
}

const FormAlert: FunctionComponent<FormAlertProps> = ({
  title,
  message,
  ...props
}): JSX.Element => {
  return (
    <Alert {...props} className="trv-form-alert">
      {title && (
        <>
          <b>{title}</b>
          <br />
        </>
      )}
      {message}
    </Alert>
  );
};

export default FormAlert;
