import * as Yup from 'yup';
import {
  ValidationSchema,
  ValidationShape,
  getConditionalStringValidator,
  getEndDateValidator,
  getNumberValidator,
} from '@/helpers/validation';

export const getValidationSchema = (): ValidationSchema => {
  const validationShape: ValidationShape = {
    startDate: getConditionalStringValidator('Start Date', 'endDate'),
    endDate: getEndDateValidator('End Date', 'Start Date', 'startDate'),
    cashbackPercentage: getNumberValidator('Cashback Multiplier'),
    minimumCommissionPercentage: getNumberValidator(
      'Minimum Commission Multiplier',
    ),
    payoutDays: getNumberValidator('Payout Days'),
  };

  // Having the array pairs in the end is needed to fix circular dependency problems
  // when we have two fields which are validating against each other.
  return Yup.object().shape(validationShape, [['startDate', 'endDate']]);
};
