import React, {
  FunctionComponent,
  useState,
  FormEvent,
  useEffect,
} from 'react';
import { Formik, FormikHelpers, Field } from 'formik';
import moment from 'moment';
import { Col, Form as BaseForm } from 'react-bootstrap';
import { isEmpty } from 'ramda';
import { useMutation } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { formatDateInZone } from '@/helpers/date';
import Box from '@/components/Box';
import Button from '@/components/Button';
import InAppPromoDisplayModal from '@/scenes/Travel/scenes/Promotions/components/InAppPromoDisplayModal';
import IconForOpeningModal from '@/scenes/Travel/scenes/Promotions/components/IconForOpeningModal';
import Form from '@/components/Form';
import FormAlert from '@/components/FormAlert';
import FormField from '@/components/FormField';
import FormDateField from '@/components/FormDateField';
import FormPreviewField from '@/components/FormPreviewField';
import FormPromotionRules from '../FormPromotionRules';
import { PROMOTION_FORM_SUBMIT_BTN_ID } from '@/scenes/Travel/scenes/Promotions/components/PromotionForm/PromotionForm.types';
import { VALIDATION_ERROR_MSG, getValidationError } from '@/helpers/validation';
import {
  EditLivePromotionFormData,
  EditLivePromotionFormProps,
  EditLivePromotionRequestParams,
} from './EditLivePromotionForm.types';
import { UserInfo } from '@/helpers/user';
import { getValidationSchema } from './EditLivePromotionForm.validators';
import { transformEditLivePromotionFormDataToParams } from './EditLivePromotionForm.transforms';

const formatCheckInOutDate = (date: string): string => {
  return !isEmpty(date) ? formatDateInZone(date) : '';
};

const handlePreSubmit = (
  e: FormEvent<HTMLFormElement>,
  isValid: boolean,
  setStatus: Function,
  triggerSubmit: Function,
) => {
  window.scrollTo({ top: 0 });

  if (!isValid) {
    setStatus({ success: false, error: VALIDATION_ERROR_MSG });
  }

  triggerSubmit(e);
};

const handleFormSubmit = async (
  values: EditLivePromotionFormData,
  actions: FormikHelpers<EditLivePromotionFormData>,
  userInfo: UserInfo,
  updateMarketingOffer: Function,
): Promise<void> => {
  const { setStatus } = actions;

  try {
    const params: EditLivePromotionRequestParams =
      transformEditLivePromotionFormDataToParams(values);
    await updateMarketingOffer({ variables: { ...params, ...userInfo } });
    setStatus({ success: true });
  } catch (error) {
    setStatus({ success: false, error: getValidationError(error) });
  }
};

const EditLivePromotionForm: FunctionComponent<EditLivePromotionFormProps> = ({
  initialValues,
  mutation,
  errorMessage,
  onLoading,
  onSave,
}): JSX.Element => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserInfo>({
    userName: '',
    userId: '',
  });
  const [showPromoModal, setShowPromoModal] = useState<boolean>(false);
  const [updateMarketingOffer, { data, loading }] = useMutation(mutation);

  const updateUserInfo = async () => {
    const user = await oktaAuth.getUser();

    if (user) {
      setUserInfo({ userName: user.name || '', userId: user.email || '' });
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated && userInfo.userName === '') {
      updateUserInfo();
    }
  });

  useEffect(() => {
    onLoading(loading);

    if (data && onSave) {
      onSave();
    }
  }, [loading]);

  return (
    <Box>
      <Formik
        validateOnMount={false}
        enableReinitialize
        initialValues={initialValues as EditLivePromotionFormData}
        validationSchema={getValidationSchema()}
        onSubmit={(values, actions) =>
          handleFormSubmit(values, actions, userInfo, updateMarketingOffer)
        }
      >
        {({ handleSubmit, isSubmitting, isValid, status, setStatus }) => (
          <Form
            narrow
            disabled={isSubmitting}
            onSubmit={(e: FormEvent<HTMLFormElement>) =>
              handlePreSubmit(e, isValid, setStatus, handleSubmit)
            }
          >
            {status && !status.success && (
              <FormAlert
                variant="danger"
                title={errorMessage}
                message={status.error}
              />
            )}
            <FormAlert
              variant="primary"
              message="Remember to enter all dates using the Austin, Texas timezone."
            />
            <BaseForm.Row>
              <FormDateField
                as={Col}
                label="Start Date"
                name="startDate"
                md
                disabled
              />
              <FormDateField
                as={Col}
                label="End Date"
                name="endDate"
                dayPickerProps={{
                  disabledDays: {
                    before: moment(initialValues.startDate)
                      .add(1, 'days')
                      .toDate(),
                  },
                }}
                md
                disabled={isSubmitting}
              />
            </BaseForm.Row>
            <FormField
              label="Promo Name"
              name="promoName"
              maxLength={32}
              disabled={isSubmitting}
            />
            <BaseForm.Row>
              <Col md>
                <FormPreviewField
                  label="Check In After Date"
                  value={formatCheckInOutDate(initialValues.checkInAfter)}
                />
              </Col>
              <Col md>
                <FormPreviewField
                  label="Check In Before Date"
                  value={formatCheckInOutDate(initialValues.checkInBefore)}
                />
              </Col>
            </BaseForm.Row>
            <BaseForm.Row>
              <Col md>
                <FormPreviewField
                  label="Check Out After Date"
                  value={formatCheckInOutDate(initialValues.checkOutAfter)}
                />
              </Col>
              <Col md>
                <FormPreviewField
                  label="Check Out Before Date"
                  value={formatCheckInOutDate(initialValues.checkOutBefore)}
                />
              </Col>
            </BaseForm.Row>
            <FormPromotionRules initialValues={initialValues} />
            <FormPreviewField
              label="In-App Title"
              value={initialValues.inAppTitle}
              icon={
                <IconForOpeningModal onClick={() => setShowPromoModal(true)} />
              }
            />
            <FormPreviewField
              label="In-App Headline"
              value={initialValues.inAppHeadline}
              icon={
                <IconForOpeningModal onClick={() => setShowPromoModal(true)} />
              }
            />
            <FormPreviewField
              label="In-App Confirmation"
              value={initialValues.inAppConfirmation}
              icon={
                <IconForOpeningModal onClick={() => setShowPromoModal(true)} />
              }
            />
            <FormPreviewField
              label="In-App Promotion Description"
              value={initialValues.inAppPromotionDescription}
              icon={
                <IconForOpeningModal onClick={() => setShowPromoModal(true)} />
              }
            />
            <Field type="hidden" name="version" />
            <Field type="hidden" name="offerId" />
            {/* The button is hidden because it's triggered via script */}
            <Button
              id={PROMOTION_FORM_SUBMIT_BTN_ID}
              type="submit"
              disabled={isSubmitting}
              style={{ display: 'none' }}
            />
          </Form>
        )}
      </Formik>
      <InAppPromoDisplayModal
        show={showPromoModal}
        onHide={() => setShowPromoModal(false)}
      />
    </Box>
  );
};

export default EditLivePromotionForm;
