export const COMMISSION_SPLIT_FORM_SUBMIT_BTN_ID =
  'commission-split-form-submit-btn';

export type CommissionSplitQualifier = 'FIRST_BOOKING_ONLY' | null;

export interface CommissionSplitFormData {
  offerId: string;
  startDate: string;
  endDate: string;
  version: string;
  cashbackPercentage: string;
  minimumCommissionPercentage: string;
  payoutDays: string;
  userSegment: string;
}

export interface CommissionSplitFormProps {
  validateOnMount: boolean;
  initialValues: CommissionSplitFormData;
  mutation: any;
  errorMessage: string;
  onLoading(loading: boolean): void;
  onSave?(): void;
  redirectAfterSave?: {
    pathname: string;
    state: {
      flashMessage: string;
    };
  };
}

export interface CommissionSplitRequestParams {
  offerId: string;
  from: string;
  end: string;
  cashbackPercentage: number;
  minimumCommissionPercentage: number;
  payoutDays: number;
  version?: string;
  qualifier: CommissionSplitQualifier;
}
