import React, {
  FunctionComponent,
  useState,
  FormEvent,
  ChangeEvent,
  useEffect,
} from 'react';
import { Formik, FormikHelpers, FormikErrors, Field } from 'formik';
import { Col, Form as BaseForm } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom';
import InAppPromoDisplayModal from '../InAppPromoDisplayModal';
import IconForOpeningModal from '../IconForOpeningModal';
import Box from '@/components/Box';
import Button from '@/components/Button';
import Form from '@/components/Form';
import FormAlert from '@/components/FormAlert';
import FormSelect from '@/components/FormSelect';
import FormField from '@/components/FormField';
import FormDateField from '@/components/FormDateField';
import FormTextarea from '@/components/FormTextarea';
import { UserInfo } from '@/helpers/user';
import { VALIDATION_ERROR_MSG, getValidationError } from '@/helpers/validation';
import {
  PROMOTION_FORM_SUBMIT_BTN_ID,
  PromotionFormData,
  PromotionFormProps,
  PromotionRequestParams,
} from './PromotionForm.types';
import {
  PROMO_CODE_ERROR_MSG,
  getValidationSchema,
} from './PromotionForm.validators';
import { transformPromotionFormDataToParams } from './PromotionForm.transforms';

const handlePreSubmit = (
  e: FormEvent<HTMLFormElement>,
  isValid: boolean,
  errors: FormikErrors<PromotionFormData>,
  setStatus: Function,
  triggerSubmit: Function,
) => {
  window.scrollTo({ top: 0 });

  // Display general validation error only when some fields are not valid,
  // and when promo code does not have a custom ("already in use") error.
  if (!isValid && !(errors.promoCode === PROMO_CODE_ERROR_MSG)) {
    setStatus({ success: false, error: VALIDATION_ERROR_MSG });
  }

  triggerSubmit(e);
};

const handleFormSubmit = async (
  values: PromotionFormData,
  actions: FormikHelpers<PromotionFormData>,
  userInfo: UserInfo,
  addOrUpdateMarketingOffer: Function,
): Promise<void> => {
  const { setStatus, setErrors } = actions;

  try {
    const params: PromotionRequestParams =
      transformPromotionFormDataToParams(values);
    await addOrUpdateMarketingOffer({ variables: { ...params, ...userInfo } });
    setStatus({ success: true });
  } catch (error) {
    const errorMessage: string = getValidationError(error);

    // Currently, I dont see a better way to detect if promo code is already in use,
    // because the API returns "VALIDATION_ERROR" on same kind of errors as well;
    // This could be perhaps fixed by using custom status codes(?)
    if (errorMessage === 'This promoCode is already taken') {
      setStatus({ success: false, error: PROMO_CODE_ERROR_MSG });
      setErrors({ promoCode: PROMO_CODE_ERROR_MSG });
    } else {
      setStatus({ success: false, error: errorMessage });
    }
  }
};

const PromotionForm: FunctionComponent<PromotionFormProps> = ({
  validateOnMount,
  initialValues,
  mutation,
  errorMessage,
  onLoading,
  onSave,
  redirectAfterSave,
}): JSX.Element => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserInfo>({
    userName: '',
    userId: '',
  });
  const [showPromoModal, setShowPromoModal] = useState<boolean>(false);
  const [useCashbackAmount, setUseCashbackAmount] = useState<boolean>(
    initialValues.cashbackBonusRule === 'cashbackAmount',
  );
  const [usePromoCodeOffer, setUsePromoCodeOffer] = useState<boolean>(
    initialValues.offerType === 'promoCodeOffer',
  );
  const [addOrUpdateMarketingOffer, { data, loading }] = useMutation(mutation);

  const updateUserInfo = async () => {
    const user = await oktaAuth.getUser();

    if (user) {
      setUserInfo({ userName: user.name || '', userId: user.email || '' });
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated && userInfo.userName === '') {
      updateUserInfo();
    }
  });

  useEffect(() => {
    onLoading(loading);

    if (data && onSave) {
      onSave();
    }
  }, [loading]);

  return (
    <Box>
      <Formik
        validateOnMount={validateOnMount}
        enableReinitialize={!validateOnMount}
        initialValues={initialValues}
        validationSchema={getValidationSchema(
          useCashbackAmount,
          usePromoCodeOffer,
        )}
        onSubmit={(values, actions) =>
          handleFormSubmit(values, actions, userInfo, addOrUpdateMarketingOffer)
        }
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          errors,
          status,
          setStatus,
        }) => {
          if (status && status.success && redirectAfterSave) {
            return <Redirect to={redirectAfterSave} />;
          }

          return (
            <Form
              narrow
              disabled={isSubmitting}
              onSubmit={(e: FormEvent<HTMLFormElement>) =>
                handlePreSubmit(e, isValid, errors, setStatus, handleSubmit)
              }
            >
              {status && !status.success && (
                <FormAlert
                  variant="danger"
                  title={errorMessage}
                  message={status.error}
                />
              )}
              <FormAlert
                variant="primary"
                message="Remember to enter all dates using the Austin, Texas timezone."
              />
              <BaseForm.Row>
                <FormDateField
                  as={Col}
                  label="Start Date"
                  name="startDate"
                  dayPickerProps={{ disabledDays: { before: new Date() } }}
                  md
                  disabled={isSubmitting}
                />
                <FormDateField
                  as={Col}
                  label="End Date"
                  name="endDate"
                  dayPickerProps={{ disabledDays: { before: new Date() } }}
                  md
                  disabled={isSubmitting}
                />
              </BaseForm.Row>
              <BaseForm.Row>
                <FormDateField
                  as={Col}
                  label="Check In After Date"
                  name="checkInAfter"
                  md
                  disabled={isSubmitting}
                />
                <FormDateField
                  as={Col}
                  label="Check In Before Date"
                  name="checkInBefore"
                  md
                  disabled={isSubmitting}
                />
              </BaseForm.Row>
              <BaseForm.Row>
                <FormDateField
                  as={Col}
                  label="Check Out After Date"
                  name="checkOutAfter"
                  md
                  disabled={isSubmitting}
                />
                <FormDateField
                  as={Col}
                  label="Check Out Before Date"
                  name="checkOutBefore"
                  md
                  disabled={isSubmitting}
                />
              </BaseForm.Row>
              <FormField
                label="Promo Name"
                name="promoName"
                maxLength={32}
                disabled={isSubmitting}
              />
              <FormField
                label="Promo Code"
                name="promoCode"
                maxLength={16}
                disabled={isSubmitting}
              />
              <FormSelect
                label="Cash Back Bonus Rule"
                name="cashbackBonusRule"
                onChangeHandler={(e: ChangeEvent<HTMLSelectElement>) => {
                  setUseCashbackAmount(e.target.value === 'cashbackAmount');
                }}
                disabled={isSubmitting}
              >
                <option value="cashbackAmount">Cash Back Amount</option>
                <option value="cashbackMultiplier">Cash Back Multiplier</option>
              </FormSelect>
              {useCashbackAmount ? (
                <FormField
                  label="Cash Back Amount (in cents)"
                  name="cashbackAmount"
                  disabled={isSubmitting}
                />
              ) : (
                <>
                  <FormField
                    label="Cash Back Multiplier %"
                    name="cashbackMultiplier"
                    disabled={isSubmitting}
                  />
                  <FormField
                    label="Minimum Cash Back Amount (in cents)"
                    name="minimumCashbackAmount"
                    disabled={isSubmitting}
                  />
                </>
              )}
              {usePromoCodeOffer && (
                <FormField
                  label="Minimum Booking Total Amount (in cents)"
                  name="minimumBookingTotalAmount"
                  disabled={isSubmitting}
                />
              )}
              <FormSelect
                label="Offer Type"
                name="offerType"
                onChangeHandler={(e: ChangeEvent<HTMLSelectElement>) => {
                  setUsePromoCodeOffer(e.target.value === 'promoCodeOffer');
                }}
                disabled={isSubmitting}
              >
                <option>Marketing Offer</option>
                <option value="promoCodeOffer">Promo Code Offer</option>
              </FormSelect>
              <FormSelect
                label="User Segment"
                name="userSegment"
                disabled={isSubmitting}
              >
                <option value="allBookings">All Bookings</option>
                <option value="firstTimeBookers">First Time Bookers</option>
              </FormSelect>
              <FormSelect label="Test Promo" name="testPromo">
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </FormSelect>
              <FormField
                label="Max Usage (0 for unlimited)"
                name="maxUsage"
                maxLength={2}
                disabled={isSubmitting}
              />
              <FormField
                label="In-App Title"
                name="inAppTitle"
                maxLength={24}
                // prettier-ignore
                icon={<IconForOpeningModal onClick={() => setShowPromoModal(true)} />}
                disabled={isSubmitting}
              />
              <FormField
                label="In-App Headline"
                name="inAppHeadline"
                maxLength={64}
                // prettier-ignore
                icon={<IconForOpeningModal onClick={() => setShowPromoModal(true)} />}
                disabled={isSubmitting}
              />
              <FormTextarea
                label="In-App Confirmation"
                name="inAppConfirmation"
                rows={6}
                maxLength={265}
                // prettier-ignore
                icon={<IconForOpeningModal onClick={() => setShowPromoModal(true)} />}
                disabled={isSubmitting}
              />
              <FormTextarea
                label="In-App Promotion Description"
                name="inAppPromotionDescription"
                rows={6}
                maxLength={265}
                // prettier-ignore
                icon={<IconForOpeningModal onClick={() => setShowPromoModal(true)} />}
                disabled={isSubmitting}
              />
              <Field type="hidden" name="version" />
              <Field type="hidden" name="offerId" />
              {/* The button is hidden because it's triggered via script */}
              <Button
                id={PROMOTION_FORM_SUBMIT_BTN_ID}
                type="submit"
                disabled={isSubmitting}
                style={{ display: 'none' }}
              />
            </Form>
          );
        }}
      </Formik>
      <InAppPromoDisplayModal
        show={showPromoModal}
        onHide={() => setShowPromoModal(false)}
      />
    </Box>
  );
};

export default PromotionForm;
