import { PromotionCashback } from '@/scenes/Travel/scenes/Promotions/Promotions.types';

export const toCleanWord = (word: string): string => {
  return word.trim().replace('$', '').toLowerCase();
};

export const containsKeyword = (source: string, keyword: string): boolean => {
  const cleanSource: string = toCleanWord(source);
  const cleanKeyword: string = toCleanWord(keyword);

  return cleanSource.search(cleanKeyword) !== -1;
};

export const getSortingValueForCashbackAmount = (
  promotionCashback: PromotionCashback,
): number => {
  const { cashbackAmount, cashbackPercentage } = promotionCashback;

  if (cashbackAmount) {
    return cashbackAmount;
  }
  if (cashbackPercentage) {
    // The magic value of `1000000` ensures that promotions with cash back percentage is sorted
    // always above the offers with cash back amount.
    return 1000000 + cashbackPercentage;
  }
  throw new Error(
    `Could not get sorting value for promotion, data is corrupted?`,
  );
};
