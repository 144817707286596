import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import Routes from './Routes';
import { MainLayout } from './components/Layouts';
import 'react-toastify/dist/ReactToastify.min.css';
import './styles/global.css';

const oktaAuth = new OktaAuth({
  issuer: `${process.env.OKTA_DOMAIN}`,
  clientId: process.env.OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/implicit/callback`,
});

const SecureApp: React.FunctionComponent = (): JSX.Element => {
  const history = useHistory();
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <MainLayout>
        <Routes />
      </MainLayout>
    </Security>
  );
};

export default SecureApp;
