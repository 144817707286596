import React, { FunctionComponent, ElementType } from 'react';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import { isEmpty } from 'ramda';
import { Form } from 'react-bootstrap';
import Feedback from 'react-bootstrap/Feedback';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DayPickerInputProps } from 'react-day-picker';
import {
  CALENDAR_DATE_FORMAT,
  ISO_8601_DATE_FORMAT,
  formatDateInZone,
  parseDateInZone,
} from '@/helpers/date';
import 'react-day-picker/lib/style.css';
import './FormDateField.css';

type FormDateFieldProps = {
  label: string;
  as?: ElementType;
  md?: boolean;
} & DayPickerInputProps &
  FieldAttributes<{}>;

const getClassNameWithError = (
  className: string,
  withError: boolean,
): string => {
  return `${className} ${withError ? 'trv-form-date-field--invalid' : ''}`;
};

const FormDateField: FunctionComponent<FormDateFieldProps> = ({
  label,
  disabled,
  as,
  md,
  ...props
}): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField<{}>(props);
  const displayValue: string = !isEmpty(field.value)
    ? formatDateInZone(field.value as string)
    : '';
  const errorText: string | null =
    meta.error && meta.touched ? meta.error : null;
  const className: string = getClassNameWithError(
    'trv-form-date-field',
    !!errorText,
  );

  /* eslint-disable */
  return (
    <Form.Group
      // @ts-ignore
      as={as}
      controlId={props.name}
      style={{ marginBottom: '1.5rem' }}
      md={md}
    >
      <Form.Label>{label}</Form.Label>
      <DayPickerInput
        formatDate={formatDateInZone}
        parseDate={parseDateInZone}
        format={CALENDAR_DATE_FORMAT}
        placeholder={CALENDAR_DATE_FORMAT}
        value={displayValue}
        onDayChange={(day) => {
          setFieldValue(
            props.name,
            formatDateInZone(day, ISO_8601_DATE_FORMAT),
          );
        }}
        inputProps={{
          name: props.name,
          id: props.name,
          readOnly: true,
          disabled,
          className,
        }}
        dayPickerProps={props.dayPickerProps}
      />
      {errorText ? (
        <Feedback type="invalid" style={{ display: 'block' }}>
          {errorText}
        </Feedback>
      ) : null}
    </Form.Group>
  );
  /* eslint-enable */
};

export default FormDateField;
