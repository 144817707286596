import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://18bf38ba129d485e8f3229194e0276b7@sentry.io/1845231',
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
