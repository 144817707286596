import gql from 'graphql-tag';

export const GET_COMMISSION_OFFERS = gql`
  query travelCommissionOffers($offerIds: [ID!]) {
    travelCommissionOffers(offerIds: $offerIds) {
      offers {
        offerId
        from
        end
        cashbackPercentage
        payoutDays
      }
    }
  }
`;
