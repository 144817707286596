import React, { FunctionComponent } from 'react';
import {
  Modal as BaseModal,
  ModalProps as BaseModalProps,
} from 'react-bootstrap';
import Button from '@/components/Button';
import './Modal.css';

interface ModalProps extends BaseModalProps {
  heading: string;
  closeButtonText?: string;
}

const Modal: FunctionComponent<ModalProps> = ({
  children,
  heading,
  closeButtonText,
  onHide,
  ...props
}): JSX.Element => {
  return (
    <BaseModal {...props} onHide={onHide} dialogClassName="trv-modal-dialog">
      <BaseModal.Header closeButton className="trv-modal-header">
        <BaseModal.Title className="trv-modal-title">{heading}</BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body>{children}</BaseModal.Body>
      <BaseModal.Footer className="trv-modal-footer">
        <Button variant="primary" onClick={onHide}>
          {closeButtonText}
        </Button>
      </BaseModal.Footer>
    </BaseModal>
  );
};

Modal.defaultProps = {
  closeButtonText: 'OK',
};

export default Modal;
