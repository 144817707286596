import React, { FunctionComponent } from 'react';
import { FieldAttributes, useField } from 'formik';
import { Form } from 'react-bootstrap';
import Feedback from 'react-bootstrap/Feedback';
import './FormTextarea.css';

type FormTextareaProps = {
  label: string;
  rows?: number;
  icon?: JSX.Element;
} & FieldAttributes<{}>;

const getClassNameWithError = (
  className: string,
  withError: boolean,
): string => {
  return `${className} ${withError ? 'trv-form-textarea--invalid' : ''}`;
};

const FormTextarea: FunctionComponent<FormTextareaProps> = ({
  label,
  icon,
  ...props
}): JSX.Element => {
  const [field, meta] = useField<{}>(props);
  const errorText: string | null =
    meta.error && meta.touched ? meta.error : null;
  const className: string = getClassNameWithError(
    'trv-form-textarea',
    !!errorText,
  );

  /* eslint-disable */
  return (
    <Form.Group controlId={field.name} style={{ marginBottom: '1.5rem' }}>
      <Form.Label>
        {label} {icon}
      </Form.Label>
      {
        // @ts-ignore
        <Form.Control as="textarea" {...{ ...field, ...props, className }} />
      }
      {errorText ? (
        <Feedback type="invalid" style={{ display: 'block' }}>
          {errorText}
        </Feedback>
      ) : null}
    </Form.Group>
  );
  /* eslint-enable */
};

FormTextarea.defaultProps = {
  rows: 3,
};

export default FormTextarea;
