import React, { FunctionComponent, ChangeEvent } from 'react';
import { FieldAttributes, useField } from 'formik';
import { Form } from 'react-bootstrap';
import Feedback from 'react-bootstrap/Feedback';
import './FormSelect.css';

type FormSelectProps = {
  label: string;
  onChangeHandler?(e: ChangeEvent<HTMLSelectElement>): void;
} & FieldAttributes<{}>;

const getClassNameWithError = (
  className: string,
  withError: boolean,
): string => {
  return `${className} ${withError ? 'trv-form-select--invalid' : ''}`;
};

const FormSelect: FunctionComponent<FormSelectProps> = ({
  label,
  onChangeHandler,
  children,
  ...props
}): JSX.Element => {
  const [field, meta] = useField<{}>(props);
  const errorText: string | null =
    meta.error && meta.touched ? meta.error : null;
  const className: string = getClassNameWithError(
    'trv-form-select',
    !!errorText,
  );
  const onChange = !onChangeHandler
    ? field.onChange
    : (e: ChangeEvent<HTMLSelectElement>) => {
        onChangeHandler(e);
        field.onChange(e);
      };

  /* eslint-disable */
  return (
    <Form.Group controlId={props.name} style={{ marginBottom: '1.5rem' }}>
      <Form.Label>{label}</Form.Label>
      {
        /* prettier-ignore */
        // @ts-ignore}
        <Form.Control as="select" {...{ ...field, ...props, onChange, className }}>{children}</Form.Control>
      }
      {errorText ? (
        <Feedback type="invalid" style={{ display: 'block' }}>
          {errorText}
        </Feedback>
      ) : null}
    </Form.Group>
  );
  /* eslint-enable */
};

export default FormSelect;
