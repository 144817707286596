import React, { FunctionComponent } from 'react';
import { SimpleLayout } from '@/components/Layouts';

const NotFound: FunctionComponent = (): JSX.Element => {
  return (
    <SimpleLayout>
      <h1>404</h1>
      <p>Page not found :(</p>
    </SimpleLayout>
  );
};

export default NotFound;
