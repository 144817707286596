import { ISO_8601_DATE_FORMAT, formatDateInZone } from '@/helpers/date';
import {
  PromotionFormData,
  PromotionQualifier,
} from '@/scenes/Travel/scenes/Promotions/components/PromotionForm/PromotionForm.types';
import { EditPromotionResponse } from './EditPromotion.types';

const numberToStr = (value: number | null | undefined): string => {
  return value !== (undefined || null) ? `${value}` : '';
};

const strToDate = (value: string | undefined): string => {
  return value ? formatDateInZone(value, ISO_8601_DATE_FORMAT) : '';
};

const transformQualifiersToOfferType = (
  qualifiers: PromotionQualifier[],
): string => {
  return qualifiers.includes('PROMO_CODE_ENTRY') ? 'promoCodeOffer' : '';
};

const transformQualifiersToUserSegment = (
  qualifiers: PromotionQualifier[],
): string => {
  return qualifiers.includes('EVERY_BOOKING')
    ? 'allBookings'
    : 'firstTimeBookers';
};

export const transformPromotionResponseToFormData = (
  promotion: EditPromotionResponse,
): PromotionFormData => {
  return {
    offerId: promotion.offerId,
    startDate: promotion.from,
    endDate: promotion.end,
    version: promotion.version,
    checkInBefore: strToDate(promotion.checkInBefore),
    checkInAfter: strToDate(promotion.checkInAfter),
    checkOutBefore: strToDate(promotion.checkOutBefore),
    checkOutAfter: strToDate(promotion.checkOutAfter),
    promoName: promotion.name || '',
    promoCode: promotion.promoCode || '',
    cashbackBonusRule: promotion.cashbackAmount
      ? 'cashbackAmount'
      : 'cashbackMultiplier',
    cashbackAmount: numberToStr(promotion.cashbackAmount),
    cashbackMultiplier: numberToStr(promotion.cashbackPercentage),
    minimumCashbackAmount: numberToStr(promotion.minimumCashbackAmount),
    minimumBookingTotalAmount: numberToStr(promotion.minimumBookingTotalAmount),
    offerType: transformQualifiersToOfferType(promotion.qualifiers),
    userSegment: transformQualifiersToUserSegment(promotion.qualifiers),
    maxUsage: numberToStr(promotion.maxUseCount),
    testPromo: promotion.testOffer ? 'yes' : 'no',
    inAppTitle: promotion.title,
    inAppHeadline: promotion.headline,
    inAppConfirmation: promotion.paragraph,
    inAppPromotionDescription: promotion.promotionMessage,
  };
};
