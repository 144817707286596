import { pathOr, isNil, isEmpty, contains, path } from 'ramda';
import { GraphQLError } from 'graphql';
import { ApolloError } from '@apollo/client';

export interface ModalError {
  displayMessage: string;
  message: string;
}

const defaultErrorMessage: ModalError = {
  displayMessage: 'Something went wrong',
  message: 'Not enough data to display at this time.',
};

const getApolloError = (errors?: ApolloError | GraphQLError[]) => {
  const apolloError = pathOr('', ['graphQLErrors'], errors);

  if (!isNil(apolloError) && !isEmpty(apolloError)) {
    return { error: apolloError, isApolloError: true };
  }

  return { isApolloError: false };
};

const getError = (errors: any): any => {
  const apolloError = getApolloError(errors);

  if (apolloError.isApolloError) {
    return apolloError.error;
  }

  if (errors) {
    if (errors.networkError) {
      const networkError = path(['result', 'errors', 0], errors.networkError);

      if (networkError) {
        return networkError;
      }
    }

    if (contains('Network error', errors.message)) {
      return {
        displayMessage: 'Something went wrong: unable to access the network',
        message: errors.message,
      };
    }

    // Unknown Error
    return {
      displayMessage: 'Something went wrong',
      message: errors.message,
    };
  }

  return null;
};

const getGraphQLError = (
  defaultError: ModalError,
  throwError: boolean = false,
  errors?: ApolloError | GraphQLError[],
): ModalError[] => {
  const error = getError(errors) || defaultError;

  if (throwError) {
    throw new Error(JSON.stringify([error]));
  }

  if (Array.isArray(error)) {
    return error;
  }

  return [error];
};

export { getApolloError, getGraphQLError, defaultErrorMessage };
