import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, ApolloError } from '@apollo/client';
import { Col, Row } from 'react-bootstrap';
import {
  ModalError,
  getGraphQLError,
  defaultErrorMessage,
} from '@/helpers/getGraphQLError';
import { toastMapping } from '@/components/utils/ErrorBoundary/ErrorBoundary';
import Breadcrumb from '@/components/Breadcrumb';
import Button from '@/components/Button';
import Container from '@/components/Container';
import NotFound from '@/scenes/NotFound';
import Loader from '@/components/Loader';
import OfferUpdateLog from '@/scenes/Travel/components/OfferUpdateLog';
import CommissionSplitStatus from './components/CommissionSplitStatus';
import CommissionSplitForm from '@/scenes/Travel/scenes/CommissionSplits/components/CommissionSplitForm';
import { COMMISSION_SPLIT_FORM_SUBMIT_BTN_ID } from '@/scenes/Travel/scenes/CommissionSplits/components/CommissionSplitForm/CommissionSplitForm.types';
import { triggerClick } from '@/helpers/dom';
import {
  GET_COMMISSION_OFFER,
  UPDATE_COMMISSION_OFFER,
} from './EditCommissionSplit.queries';
import { GET_OFFER_VERSIONS } from '@/scenes/Travel/components/OfferUpdateLog/OfferUpdateLog.queries';
import { transformCommissionSplitResponseToFormData } from './EditCommissionSplit.transforms';
import { EditCommissionSplitParams } from './EditCommissionSplit.types';
import './EditCommissionSplit.css';

const EditCommissionSplit: FunctionComponent = (): JSX.Element => {
  const params = useParams<EditCommissionSplitParams>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);

  const {
    loading: loadingSplits,
    error: getSplitsError,
    data: splitData,
    refetch: refetchSplits,
  } = useQuery(GET_COMMISSION_OFFER, {
    variables: { offerIds: [params.id] },
    fetchPolicy: 'network-only',
  });

  const {
    loading: loadingVersions,
    error: getVersionsError,
    data: versionData,
    refetch: refetchVersions,
  } = useQuery(GET_OFFER_VERSIONS, {
    variables: { offerId: params.id },
    fetchPolicy: 'network-only',
  });

  const error: ApolloError | undefined = getSplitsError || getVersionsError;

  useEffect(() => {
    if (error) {
      const formattedError: ModalError[] = getGraphQLError(
        defaultErrorMessage,
        false,
        error,
      );
      toastMapping({}, 'error')(formattedError);
    }
  }, [error]);

  useEffect(() => {
    if (saved) {
      toastMapping(
        {},
        'success',
      )([
        {
          displayMessage: 'All changes saved successfully',
          message: '',
        },
      ]);
      refetchSplits();
      refetchVersions();
      setSaved(false);
    }
  }, [saved]);

  const loading: boolean = loadingSplits || loadingVersions;

  if (loading || error) {
    return <Loader />;
  }

  const commissionSplit: any = splitData.travelCommissionOffers.offers[0];

  if (!commissionSplit) {
    return <NotFound />;
  }

  return (
    <>
      <Breadcrumb
        link={{ label: 'All Promos', href: '/' }}
        currentPage="Edit Commission Split"
      >
        <Button
          onClick={() => triggerClick(COMMISSION_SPLIT_FORM_SUBMIT_BTN_ID)}
          loading={submitting}
        >
          Save Changes
        </Button>
      </Breadcrumb>
      <Container>
        <Row>
          <Col className="trv-edit-commission-split-status">
            <CommissionSplitStatus commissionSplit={commissionSplit} />
          </Col>
        </Row>
        <Row>
          <Col lg={7} className="trv-edit-commission-split-form">
            <CommissionSplitForm
              validateOnMount={false}
              initialValues={transformCommissionSplitResponseToFormData(
                commissionSplit,
              )}
              mutation={UPDATE_COMMISSION_OFFER}
              errorMessage="Updating commission split failed"
              onLoading={setSubmitting}
              onSave={() => setSaved(true)}
            />
          </Col>
          <Col lg={5}>
            <OfferUpdateLog
              offerUpdates={versionData.travelOfferVersions.versions}
              offerType="commission split"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditCommissionSplit;
