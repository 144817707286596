import React, { FunctionComponent } from 'react';
import { Container as BaseContainer } from 'react-bootstrap';
import './Container.css';

interface ContainerProps {
  narrow?: boolean;
  noPadding?: boolean;
  className?: string;
}

const getClassNames = (props: ContainerProps): string => {
  let classNames = 'trv-container';

  if (props.narrow) {
    classNames += ' is-narrow';
  }

  if (props.noPadding) {
    classNames += ' is-paddingless';
  }

  if (props.className) {
    classNames += ` ${props.className}`;
  }

  return classNames;
};

const Container: FunctionComponent<ContainerProps> = ({
  children,
  ...props
}): JSX.Element => {
  return (
    <BaseContainer fluid className={getClassNames(props)}>
      {children}
    </BaseContainer>
  );
};

export default Container;
