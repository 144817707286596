import React, { FunctionComponent } from 'react';
import './Badge.css';

export type BadgeType = 'green' | 'orange' | 'purple';

interface BadgeProps {
  variant: BadgeType;
}

const Badge: FunctionComponent<BadgeProps> = ({
  variant,
  children,
}): JSX.Element => {
  return <span className={`trv-badge trv-badge-${variant}`}>{children}</span>;
};

export default Badge;
