import React, { FunctionComponent } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import Container from '@/components/Container';
import Promotions from './scenes/Promotions';
import CommissionSplits from './scenes/CommissionSplits';
import './Travel.css';

const Travel: FunctionComponent = (): JSX.Element => {
  return (
    <Container narrow>
      <div className="trv-travel-tabs">
        <Tab.Container id="travel-tabs-container" defaultActiveKey="promos">
          <Nav variant="pills" className="trv-travel-tabs-nav">
            <Nav.Item className="trv-travel-tabs-nav-item">
              <Nav.Link eventKey="promos">Promos</Nav.Link>
            </Nav.Item>
            <Nav.Item className="trv-travel-tabs-nav-item">
              <Nav.Link eventKey="commission-split">Commission Split</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="promos">
              <Promotions />
            </Tab.Pane>
            <Tab.Pane eventKey="commission-split">
              <CommissionSplits />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </Container>
  );
};

export default Travel;
