import React, {
  FunctionComponent,
  FormEvent,
  useEffect,
  useState,
} from 'react';
import { Formik, FormikHelpers, Field } from 'formik';
import { Col, Form as BaseForm } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom';
import Button from '@/components/Button';
import Box from '@/components/Box';
import Form from '@/components/Form';
import FormAlert from '@/components/FormAlert';
import FormDateField from '@/components/FormDateField';
import FormField from '@/components/FormField';
import FormSelect from '@/components/FormSelect';
import { UserInfo } from '@/helpers/user';
import { VALIDATION_ERROR_MSG, getValidationError } from '@/helpers/validation';
import {
  COMMISSION_SPLIT_FORM_SUBMIT_BTN_ID,
  CommissionSplitFormProps,
  CommissionSplitFormData,
  CommissionSplitRequestParams,
} from './CommissionSplitForm.types';
import { getValidationSchema } from './CommissionSplitForm.validators';
import { transformCommissionSplitFormDataToParams } from './CommissionSplitForm.transforms';

const handlePreSubmit = (
  e: FormEvent<HTMLFormElement>,
  isValid: boolean,
  setStatus: Function,
  triggerSubmit: Function,
): void => {
  window.scrollTo({ top: 0 });

  if (!isValid) {
    setStatus({ success: false, error: VALIDATION_ERROR_MSG });
  }

  triggerSubmit(e);
};

const handleFormSubmit = async (
  values: CommissionSplitFormData,
  actions: FormikHelpers<CommissionSplitFormData>,
  userInfo: UserInfo,
  addOrUpdateCommissionOffer: Function,
): Promise<void> => {
  const { setStatus } = actions;

  try {
    const params: CommissionSplitRequestParams =
      transformCommissionSplitFormDataToParams(values);
    await addOrUpdateCommissionOffer({ variables: { ...params, ...userInfo } });
    setStatus({ success: true });
  } catch (error) {
    setStatus({ success: false, error: getValidationError(error) });
  }
};

const CommissionSplitForm: FunctionComponent<CommissionSplitFormProps> = ({
  validateOnMount,
  initialValues,
  mutation,
  errorMessage,
  onLoading,
  onSave,
  redirectAfterSave,
}): JSX.Element => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserInfo>({
    userName: '',
    userId: '',
  });
  const [addOrUpdateCommissionOffer, { data, loading }] = useMutation(mutation);

  const updateUserInfo = async (): Promise<void> => {
    const user = await oktaAuth.getUser();

    if (user) {
      setUserInfo({ userName: user.name || '', userId: user.email || '' });
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated && userInfo.userName === '') {
      updateUserInfo();
    }
  });

  useEffect(() => {
    onLoading(loading);

    if (data && onSave) {
      onSave();
    }
  }, [loading]);

  return (
    <Box>
      <Formik
        validateOnMount={validateOnMount}
        enableReinitialize={!validateOnMount}
        validationSchema={getValidationSchema()}
        initialValues={initialValues}
        onSubmit={(values, actions) =>
          handleFormSubmit(
            values,
            actions,
            userInfo,
            addOrUpdateCommissionOffer,
          )
        }
      >
        {({ handleSubmit, isSubmitting, isValid, status, setStatus }) => {
          if (status && status.success && redirectAfterSave) {
            return <Redirect to={redirectAfterSave} />;
          }

          return (
            <Form
              narrow
              disabled={isSubmitting}
              onSubmit={(e: FormEvent<HTMLFormElement>) =>
                handlePreSubmit(e, isValid, setStatus, handleSubmit)
              }
            >
              {status && !status.success && (
                <FormAlert
                  variant="danger"
                  title={errorMessage}
                  message={status.error}
                />
              )}
              <FormAlert
                variant="primary"
                message="Remember to enter all dates using the Austin, Texas timezone."
              />
              <BaseForm.Row>
                <FormDateField
                  as={Col}
                  label="Start Date"
                  name="startDate"
                  dayPickerProps={{ disabledDays: { before: new Date() } }}
                  md
                  disabled={isSubmitting}
                />
                <FormDateField
                  as={Col}
                  label="End Date"
                  name="endDate"
                  dayPickerProps={{ disabledDays: { before: new Date() } }}
                  md
                  disabled={isSubmitting}
                />
              </BaseForm.Row>
              <FormField
                label="Cashback Multiplier %"
                name="cashbackPercentage"
                disabled={isSubmitting}
              />
              <FormField
                label="Minimum Commission Multiplier %"
                name="minimumCommissionPercentage"
                disabled={isSubmitting}
              />
              <FormSelect
                label="User Segment"
                name="userSegment"
                disabled={isSubmitting}
              >
                <option>All Bookings</option>
                <option value="firstTimeBookers">First Time Bookers</option>
              </FormSelect>
              <FormField
                label="Payout Days"
                name="payoutDays"
                disabled={isSubmitting}
              />
              <Field type="hidden" name="version" />
              <Field type="hidden" name="offerId" />
              {/* The button is hidden because it's triggered via script */}
              <Button
                id={COMMISSION_SPLIT_FORM_SUBMIT_BTN_ID}
                type="submit"
                disabled={isSubmitting}
                style={{ display: 'none' }}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CommissionSplitForm;
