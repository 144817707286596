import React, { FunctionComponent, useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Image, NavDropdown } from 'react-bootstrap';
import Logo from '@/assets/images/logos/dosh.svg';
import './Navbar.css';

const Navbar: FunctionComponent = (): JSX.Element => {
  const { authState, oktaAuth } = useOktaAuth();
  const [name, setName] = useState<string>('');
  const location = useLocation();
  let navbarContainerClassName = 'trv-navbar-container';

  if (location.pathname !== '/') {
    navbarContainerClassName += ' trv-navbar-container--with-breadcrumb';
  }

  const getName = async () => {
    const user = await oktaAuth.getUser();

    if (user && user.name) {
      setName(user.name);
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated && name.length === 0) {
      getName();
    }
  });

  const logout = async () => {
    oktaAuth.signOut({
      postLogoutRedirectUri: window.location.origin,
    });
  };

  return (
    <div className={navbarContainerClassName}>
      <Container fluid>
        <nav className="trv-navbar">
          <Link to="/">
            <Image src={Logo} width={40} height={40} />
          </Link>
          <div className="trv-navbar-menu">
            <Link to="/" className="trv-navbar-link trv-navbar-link--active">
              Travel
            </Link>
          </div>
          <NavDropdown
            alignRight
            className="trv-navbar-dropdown"
            title={name}
            id="nav-dropdown"
          >
            <NavDropdown.Item onClick={logout}>
              Log out &raquo;
            </NavDropdown.Item>
          </NavDropdown>
        </nav>
      </Container>
    </div>
  );
};

export default Navbar;
