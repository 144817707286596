import React, { FunctionComponent } from 'react';
import './DateFormatNotification.css';

const DateFormatNotification: FunctionComponent = (): JSX.Element => {
  return (
    <div className="trv-date-format-notifacation">
      All the dates and times are displayed in Austin, Texas timezone.
    </div>
  );
};

export default DateFormatNotification;
