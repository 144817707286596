import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import Badge from '@/components/Badge/Badge';
import Table, { TableColumn, TableRow } from '@/components/Table/Table';
import TableLoader from '@/components/TableLoader';
import { formatDateInZone } from '@/helpers/date';
import {
  getBadgeVariant,
  getOfferStatus,
} from '@/scenes/Travel/Travel.helpers';
import { formatCashbackAmount } from '@/scenes/Travel/scenes/Promotions/Promotions.helpers';
import {
  PromotionTableProps,
  PromotionTableData,
} from './PromotionTable.types';
import {
  containsKeyword,
  getSortingValueForCashbackAmount,
} from './PromotionTable.helpers';

const PromotionTable: FunctionComponent<PromotionTableProps> = ({
  promotions,
  searchKeyword,
  loading,
}): JSX.Element => {
  if (loading) {
    return <TableLoader />;
  }

  const history = useHistory();
  const filteredPromotions: PromotionTableData[] = promotions.filter(
    (promotion) => {
      // All promos does not have name property, so we fallback to title
      const promoNameOrTitle: string = promotion.name || promotion.title;
      const promoNameOrTitleMatchesKeyword: boolean = containsKeyword(
        promoNameOrTitle,
        searchKeyword,
      );
      const promoCodeMatchesKeyword: boolean = promotion.promoCode
        ? containsKeyword(promotion.promoCode, searchKeyword)
        : false;

      return promoNameOrTitleMatchesKeyword || promoCodeMatchesKeyword;
    },
  );

  const columns: TableColumn[] = [
    { title: 'Name', field: 'name' },
    { title: 'Promo Code', field: 'promoCode' },
    {
      title: 'Cash back Amount',
      field: 'cashBackAmount',
      format(tableCellValue) {
        return formatCashbackAmount(tableCellValue);
      },
      sortingValue(tableCellValue) {
        return getSortingValueForCashbackAmount(tableCellValue);
      },
    },
    {
      title: 'Start Date',
      field: 'startDate',
      sortType: 'datetime',
      format(tableCellValue) {
        return formatDateInZone(tableCellValue, 'll');
      },
    },
    {
      title: 'End Date',
      field: 'endDate',
      sortType: 'datetime',
      format(tableCellValue) {
        return formatDateInZone(tableCellValue, 'll');
      },
    },
    {
      title: 'Status',
      field: 'status',
      format(tableCellValue) {
        return (
          <Badge variant={getBadgeVariant(tableCellValue)}>
            {tableCellValue}
          </Badge>
        );
      },
    },
  ];

  const rows: TableRow[] = filteredPromotions.map((promotion) => {
    return {
      name: promotion.name || promotion.title,
      promoCode: promotion.promoCode || '<no value>',
      cashBackAmount: {
        cashbackAmount: promotion.cashbackAmount,
        cashbackPercentage: promotion.cashbackPercentage,
        minimumCashbackAmount: promotion.minimumCashbackAmount,
      },
      startDate: promotion.from,
      endDate: promotion.end,
      status: getOfferStatus(promotion.from, promotion.end),
      meta: {
        offerId: promotion.offerId,
      },
    };
  });

  const handleRowClick = (tableRow: TableRow): void => {
    history.push(`/promotions/${tableRow.meta.offerId}/edit`);
  };

  return <Table columns={columns} rows={rows} onClickRow={handleRowClick} />;
};

export default PromotionTable;
