import React, { FunctionComponent } from 'react';
import { ListGroup, ListGroupItem } from '@/components/ListGroup';
import {
  OfferUpdate,
  OfferChange,
  OfferUpdateLogProps,
} from './OfferUpdateLog.types';
import {
  formatTimestamp,
  formatFieldLabel,
  formatFieldValue,
  formatOfferTypeValue,
  formatUserSegmentValue,
} from './OfferUpdateLog.helpers';
import './OfferUpdateLog.css';

const renderNotificationOnMissingVersionInformation = (
  offerType: string,
): JSX.Element => {
  /* eslint-disable */
  return (
    <ListGroupItem>
      <p>This {offerType} does not have a recorded version information.</p>
    </ListGroupItem>
  );
  /* eslint-enable */
};

const renderListGroupItem = (
  key: string,
  date: string,
  message: JSX.Element,
): JSX.Element => {
  return (
    <ListGroupItem key={key}>
      <p className="trv-offer-update-log-message">{message}</p>
      <div className="trv-offer-update-log-date">{date}</div>
    </ListGroupItem>
  );
};

const renderOfferCreated = (
  key: string,
  date: string,
  userName: string,
  offerType: string,
): JSX.Element => {
  /* eslint-disable */
  const message: JSX.Element = (
    <>
      <b>{userName}</b> created the {offerType}.
    </>
  );
  /* eslint-enable */

  return renderListGroupItem(key, date, message);
};

const renderFieldChangedMessage = (
  userName: string,
  field: string,
  oldValue: string,
  newValue: string,
): JSX.Element => {
  /* eslint-disable */
  return (
    <>
      <b>{userName}</b> changed the <b>{field}</b> from &quot;
      {oldValue}&quot; to &quot;{newValue}&quot;.
    </>
  );
  /* eslint-enable */
};

const renderQualifierChanged = (
  key: string,
  date: string,
  userName: string,
  offerChange: OfferChange,
): JSX.Element[] => {
  const { oldValue, newValue } = offerChange;
  const offerTypeOldValue: string = formatOfferTypeValue(oldValue);
  const offerTypeNewValue: string = formatOfferTypeValue(newValue);
  const userSegmentOldValue: string = formatUserSegmentValue(oldValue);
  const userSegmentNewValue: string = formatUserSegmentValue(newValue);
  const qualifierUpdateList: JSX.Element[] = [];

  if (offerTypeOldValue !== offerTypeNewValue) {
    const offerTypeChangedMessage: JSX.Element = renderFieldChangedMessage(
      userName,
      'Offer Type',
      offerTypeOldValue,
      offerTypeNewValue,
    );
    qualifierUpdateList.push(
      renderListGroupItem(`1-${key}`, date, offerTypeChangedMessage),
    );
  }

  if (userSegmentOldValue !== userSegmentNewValue) {
    const userSegmentChangedMessage: JSX.Element = renderFieldChangedMessage(
      userName,
      'User Segment',
      userSegmentOldValue,
      userSegmentNewValue,
    );
    qualifierUpdateList.push(
      renderListGroupItem(`2-${key}`, date, userSegmentChangedMessage),
    );
  }

  return qualifierUpdateList;
};

const renderOfferChanged = (
  key: string,
  date: string,
  userName: string,
  offerChange: OfferChange,
): JSX.Element => {
  const { field, oldValue, newValue } = offerChange;
  const formattedField: string = formatFieldLabel(field);
  const formattedOldValue: string = formatFieldValue(oldValue);
  const formattedNewValue: string = formatFieldValue(newValue);
  const message: JSX.Element = renderFieldChangedMessage(
    userName,
    formattedField,
    formattedOldValue,
    formattedNewValue,
  );

  return renderListGroupItem(key, date, message);
};

const renderOfferUpdates = (
  offerUpdates: OfferUpdate[],
  offerType: string,
): JSX.Element[] => {
  const offerUpdateList: JSX.Element[] = [];
  const sortedOfferUpdates: OfferUpdate[] = offerUpdates.slice().reverse();

  sortedOfferUpdates.forEach((offerUpdate, i) => {
    const { timestamp, userName, changes } = offerUpdate;
    const date: string = formatTimestamp(timestamp);

    // When there are no changes, we assume that it's the version entry which
    // is created along the creation of the offer.
    if (changes.length === 0) {
      offerUpdateList.push(
        renderOfferCreated(`${i}-${timestamp}`, date, userName, offerType),
      );
    }

    changes.forEach((offerChange, j) => {
      // The "qualifier" is a special field and needs to be handled differently.
      // The reason is that at the UI the value of the qualifier is controlled
      // by using two different dropdowns ("Offer Type" and "User Segment").
      if (offerChange.field === 'qualifier') {
        offerUpdateList.push(
          ...renderQualifierChanged(
            `${j}-${timestamp}`,
            date,
            userName,
            offerChange,
          ),
        );
      } else {
        offerUpdateList.push(
          renderOfferChanged(`${j}-${timestamp}`, date, userName, offerChange),
        );
      }
    });
  });

  return offerUpdateList;
};

const OfferUpdateLog: FunctionComponent<OfferUpdateLogProps> = ({
  offerUpdates,
  offerType,
}): JSX.Element => {
  const offerUpdateList: JSX.Element | JSX.Element[] =
    offerUpdates.length === 0
      ? renderNotificationOnMissingVersionInformation(offerType)
      : renderOfferUpdates(offerUpdates, offerType);

  return (
    <ListGroup>
      <ListGroupItem>Upload Log</ListGroupItem>
      <div className="trv-offer-update-log">{offerUpdateList}</div>
    </ListGroup>
  );
};

export default OfferUpdateLog;
