import gql from 'graphql-tag';

export const GET_OFFER_VERSIONS = gql`
  query travelOfferVersions($offerId: ID!) {
    travelOfferVersions(offerId: $offerId) {
      versions {
        version
        userName
        timestamp
        changes {
          field
          oldValue
          newValue
        }
      }
    }
  }
`;
