import React, { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { formatDateInZone } from '@/helpers/date';
import Box from '@/components/Box';
import Badge from '@/components/Badge';
import Container from '@/components/Container';
import {
  getOfferStatus,
  getBadgeVariant,
} from '@/scenes/Travel/Travel.helpers';
import { OfferStatusType } from '@/scenes/Travel/Travel.types';
import { CommissionSplitStatusProps } from './CommissionSplitStatus.types';
import './CommissionSplitStatus.css';

const CommissionSplitStatus: FunctionComponent<CommissionSplitStatusProps> = ({
  commissionSplit,
}): JSX.Element => {
  const commissionSplitStatus: OfferStatusType = getOfferStatus(
    commissionSplit.from,
    commissionSplit.end,
  );
  const consumerPercentage = `${commissionSplit.cashbackPercentage}%`;
  const doshPercentage = `${100.0 - commissionSplit.cashbackPercentage}%`;

  return (
    <Box>
      <Container noPadding>
        <Row>
          <Col xl={5} className="trv-commission-split-status-header">
            <h1>Commission Split</h1>
            <Badge variant={getBadgeVariant(commissionSplitStatus)}>
              {commissionSplitStatus}
            </Badge>
          </Col>
          <Col xl={7}>
            <Row className="trv-commission-split-status-panel">
              <Col lg className="trv-commission-split-status-panel-item">
                <p className="trv-commission-split-status-panel-item-label">
                  Starts
                </p>
                <p className="trv-commission-split-status-panel-item-value is-purple">
                  {formatDateInZone(commissionSplit.from, 'll')}
                </p>
              </Col>
              <Col lg className="trv-commission-split-status-panel-item">
                <p className="trv-commission-split-status-panel-item-label">
                  Ends
                </p>
                <p className="trv-commission-split-status-panel-item-value is-purple">
                  {formatDateInZone(commissionSplit.end, 'll')}
                </p>
              </Col>
              <Col lg className="trv-commission-split-status-panel-item">
                <p className="trv-commission-split-status-panel-item-label">
                  Consumer
                </p>
                <p className="trv-commission-split-status-panel-item-value is-purple">
                  {consumerPercentage}
                </p>
              </Col>
              <Col lg className="trv-commission-split-status-panel-item">
                <p className="trv-commission-split-status-panel-item-label">
                  Dosh
                </p>
                <p className="trv-commission-split-status-panel-item-value is-purple">
                  {doshPercentage}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

export default CommissionSplitStatus;
