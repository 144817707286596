import React, { FunctionComponent, ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import Icon from '../Icon';
import './SearchBar.css';

interface SearchBarProps {
  placeholder: string;
  size: 'lg' | 'sm';
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  value?: string;
  className?: string;
}

const SearchBar: FunctionComponent<SearchBarProps> = ({
  className,
  ...props
}): JSX.Element => {
  return (
    <div className="trv-search-bar-container">
      <Icon type="search" color="#a9b8c2" size="26px" />
      <Form.Control
        className={`trv-search-bar ${className}`}
        type="text"
        {...props}
      />
    </div>
  );
};

export default SearchBar;
