export const triggerClick = (buttonId: string): void => {
  const button: HTMLElement | null = document.getElementById(buttonId);

  if (!button) {
    throw new Error(
      `Could not find button with id: "${buttonId}", did your forgot to tag it?`,
    );
  }

  button.click();
};
