import React, { FunctionComponent } from 'react';
import IconButton from '@/components/IconButton';
import Icon from '@/components/Icon';

interface IconForOpeningModalProps {
  onClick(): void;
}

const IconForOpeningModal: FunctionComponent<IconForOpeningModalProps> = ({
  onClick,
}): JSX.Element => {
  return (
    <IconButton onClick={onClick}>
      <Icon type="info" />
    </IconButton>
  );
};

export default IconForOpeningModal;
