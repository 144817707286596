import * as Yup from 'yup';
import moment from 'moment';
import {
  ValidationSchema,
  ValidationShape,
  getStringValidator,
  getNumberValidator,
  getConditionalStringValidator,
  getEndDateValidator,
} from '@/helpers/validation';

export const PROMO_CODE_ERROR_MSG = 'Promo Code is already in use';

/**
 * 1) Validates that Check Out After Date cannot be empty when
 * Check Out Before Date is set.
 *
 * 2) Validates that Check Out After Date should come after
 * Check In After Date.
 *
 * @returns {Yup.StringSchema} Yup string validation schema
 */
export const getCheckOutAfterValidator = (): Yup.StringSchema => {
  return getConditionalStringValidator(
    'Check Out After Date',
    'checkOutBefore',
  ).when('checkInAfter', {
    is: (value: string) => value && value.length > 0,
    then: Yup.string()
      .required()
      .test(
        `checkOutAfter-comesAfter-checkInAfter`,
        `Should come after Check In After Date`,
        function (value) {
          return moment(value).isAfter(moment(this.parent.checkInAfter));
        },
      )
      .label('Check Out After Date'),
  });
};

export const getValidationSchema = (
  useCashbackAmount: boolean,
  usePromoCodeOffer: boolean,
): ValidationSchema => {
  const validationShape: ValidationShape = {
    promoName: getStringValidator('Promo Name'),
    promoCode: Yup.string()
      .required()
      .matches(/^[a-z0-9]+$/i, "${label} can't contain special characters") // eslint-disable-line
      .label('Promo Code'),
    maxUsage: getNumberValidator('Max Usage'),
    inAppTitle: getStringValidator('In-App Title'),
    inAppHeadline: getStringValidator('In-App Headline'),
    inAppConfirmation: getStringValidator('In-App Confirmation'),
    inAppPromotionDescription: getStringValidator(
      'In-App Promotion Description',
    ),
    startDate: getConditionalStringValidator('Start Date', 'endDate'),
    endDate: getEndDateValidator('End Date', 'Start Date', 'startDate'),
    checkInAfter: getConditionalStringValidator(
      'Check In After Date',
      'checkInBefore',
    ),
    checkInBefore: getEndDateValidator(
      'Check In Before Date',
      'Check In After Date',
      'checkInAfter',
    ),
    checkOutAfter: getCheckOutAfterValidator(),
    checkOutBefore: getEndDateValidator(
      'Check Out Before Date',
      'Check Out After Date',
      'checkOutAfter',
    ),
  };

  if (useCashbackAmount) {
    validationShape.cashbackAmount = getNumberValidator('Cash Back Amount');
  } else {
    validationShape.cashbackMultiplier = getNumberValidator(
      'Cash Back Multiplier',
    );
    validationShape.minimumCashbackAmount = getNumberValidator(
      'Minimum Cash Back Amount',
      false,
    );
  }

  if (usePromoCodeOffer) {
    validationShape.minimumBookingTotalAmount = getNumberValidator(
      'Minimum Booking Total Amount',
      false,
    );
  }

  // Having the array pairs in the end is needed to fix circular dependency problems
  // when we have two fields which are validating against each other.
  return Yup.object().shape(validationShape, [
    ['startDate', 'endDate'],
    ['checkInAfter', 'checkInBefore'],
    ['checkOutAfter', 'checkOutBefore'],
  ]);
};
