import React, { FunctionComponent } from 'react';
import { FieldAttributes, useField } from 'formik';
import { Form } from 'react-bootstrap';
import Feedback from 'react-bootstrap/Feedback';
import './FormField.css';

type FormFieldProps = {
  label: string;
  icon?: JSX.Element;
} & FieldAttributes<{}>;

const getClassNameWithError = (
  className: string,
  withError: boolean,
): string => {
  return `${className} ${withError ? 'trv-form-field--invalid' : ''}`;
};

const FormField: FunctionComponent<FormFieldProps> = ({
  label,
  icon,
  ...props
}): JSX.Element => {
  const [field, meta] = useField<{}>(props);
  const errorText: string | null =
    meta.error && meta.touched ? meta.error : null;
  const className: string = getClassNameWithError(
    'trv-form-field',
    !!errorText,
  );

  /* eslint-disable */
  return (
    <Form.Group controlId={props.name} style={{ marginBottom: '1.5rem' }}>
      <Form.Label>
        {label} {icon}
      </Form.Label>
      {
        // @ts-ignore
        <Form.Control {...{ ...field, ...props, className }} />
      }
      {errorText ? (
        <Feedback type="invalid" style={{ display: 'block' }}>
          {errorText}
        </Feedback>
      ) : null}
    </Form.Group>
  );
  /* eslint-enable */
};

export default FormField;
