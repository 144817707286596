import { isEmpty } from 'ramda';

export const strToNumberOrNull = (value: string): number | null => {
  return value ? parseInt(value, 10) : null;
};

export const formatCasbackBonusRule = (cashbackBonusRule: string): string => {
  return cashbackBonusRule === 'cashbackAmount'
    ? 'Cash Back Amount'
    : 'Cash Back Multiplier';
};

export const formatOfferType = (offerType: string): string => {
  return offerType === 'promoCodeOffer'
    ? 'Promo Code Offer'
    : 'Marketing Offer';
};

export const formatUserSegment = (userSegment: string): string => {
  return userSegment === 'firstTimeBookers'
    ? 'First Time Bookers'
    : 'All Bookings';
};

export const formatMaxUsage = (maxUsage: string): string => {
  if (isEmpty(maxUsage)) {
    return 'Max Usage <value is not set>';
  }

  const currentMaxUsage: number = parseInt(maxUsage, 10);

  if (currentMaxUsage === 0) {
    return 'Multi Use';
  }
  if (currentMaxUsage === 1) {
    return 'Single Use';
  }

  return `Can be used ${currentMaxUsage} times`;
};
