import React, { FunctionComponent } from 'react';
import './Icon.css';

const ICONS: Record<string, string> = {
  info: 'M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z',
  add: 'M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z',
  search:
    'M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z',
  chevronRight: 'M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z',
  keyboardBack:
    'M20 11H6.83l2.88-2.88c.39-.39.39-1.02 0-1.41a.9959.9959 0 00-1.41 0L3.71 11.3c-.39.39-.39 1.02 0 1.41L8.3 17.3c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L6.83 13H20c.55 0 1-.45 1-1s-.45-1-1-1z',
};

interface IconProps {
  type: 'info' | 'add' | 'search' | 'chevronRight' | 'keyboardBack';
  size?: string;
  color?: string;
}

const Icon: FunctionComponent<IconProps> = ({
  type,
  size,
  color,
}): JSX.Element => {
  return (
    <svg
      className="trv-icon"
      viewBox="0 0 24 24"
      style={{ fontSize: size, color }}
    >
      <path d={ICONS[type]} />
    </svg>
  );
};

Icon.defaultProps = {
  size: '1.5rem',
  color: '#A9A9A9',
};

export default Icon;
